@use "../utils" as u;

.customHighlight {
  box-shadow: u.$dodger-blue 0px 0px 1px 2px,
    rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}

.customTooltip {
  border: 1px solid u.$dodger-blue !important;
  height: 230px !important;
  width: 300px !important;
  color: u.$shark;
  font-family: u.$font_regular;
  letter-spacing: 0;
  font-size: u.rem(15);
  line-height: u.rem(18);
  box-shadow: u.$dodger-blue 0px 0px 1px 2px;

  &--navbar-tooltip {
    @extend .customTooltip;
    top: calc(100vh / 2) !important;
  }

  &--chart-tooltip {
    @extend .customTooltip;
  }

  &--data-tooltip {
    @extend .customTooltip;
  }

  &--consultas-tooltip {
    @extend .customTooltip;
    left: 0 !important;
    height: 270px !important;
  }

  &--consultas-tooltip .introjs-tooltipbuttons {
    height: 83px !important;
  }

  &--busqueda-tooltip {
    @extend .customTooltip;
    top: 40% !important;
    left: 120% !important;
  }

  &--busquedaReport-tooltip {
    @extend .customTooltip;
    top: 40% !important;
    right: 110% !important;
    height: 270px !important;
  }
}

.customTooltip .introjs-tooltip-header > .introjs-skipbutton {
  right: 10px !important;
  font-family: u.$font_regular;
  letter-spacing: 0;
  font-size: u.rem(14) !important;
  color: u.$dodger-blue !important;
}

.customTooltip .introjs-tooltiptext {
  width: 75%;
  height: calc(100% - 190px);
  margin: 10px auto;
  text-align: center;
}

.customTooltip .introjs-tooltipbuttons {
  height: 75px;
  display: flex;
  border: none !important;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  font-family: u.$font_regular;
  letter-spacing: 0;
  font-size: u.rem(14);
  line-height: u.rem(18);
}

.customTooltip .introjs-prevbutton {
  border: none;
  cursor: pointer;
  background-color: inherit;
  color: u.$dodger-blue;
  font-family: u.$font_regular;
  letter-spacing: 0;
  font-size: u.rem(14);
  line-height: u.rem(18);
}

.customTooltip .introjs-prevbutton:hover {
  color: u.$dodger-blue !important;
  border-color: initial !important;
  background-color: initial !important;
}

.customTooltip .introjs-nextbutton {
  border: 1px solid u.$dodger-blue;
  border-radius: 50px !important;
  background-color: u.$dodger-blue !important;
  color: white !important;
  font-family: u.$font_regular;
  letter-spacing: 0;
  font-size: u.rem(14);
  line-height: u.rem(18);
  text-shadow: none !important;
  outline: 0 solid rgba(255, 255, 255, 0) !important;
  overflow: hidden !important;
}

.introjs-arrow {
  display: none !important;
}

.introjs-button:active {
  border-color: none !important;
}

.introjs-button:focus {
  border: none !important;
  box-shadow: none !important;
}

.introjs-disabled:focus,
.introjs-disabled:hover {
  color: u.$dodger-blue !important;
  border-color: initial !important;
  background-color: initial !important;
}
