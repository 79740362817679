@use "../utils" as u;

.perfil {
  background-color: u.$zircon;

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__container {
    width: 60%;
    max-width: 630px;
    height: 20.5rem;
    padding: 24px 16px;
    background-color: u.$white;
    border: 1px solid u.$catskill-white;
    border-radius: 10px;

    &--separator {
      margin: 12px 0;
      border: 1px solid u.$catskill-white;
    }

    &--data-container {
      height: calc(20.5rem - 110px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &--data {
      width: 90%;
      margin: auto;

      &__buttons-container {
        display: flex;
        gap: 8px;
        position: relative;
      }

      &__buttons-container > .loader-container {
        width: fit-content;
        height: fit-content;
        position: absolute;
        left: -50px;
        top: -7px;
      }

      &__buttons-container .lds-ring-small {
        width: 40px;
        height: 40px;
      }

      &__buttons-container .lds-ring-small > .ringFragment {
        width: 30px;
        height: 30px;
      }
    }

    &--data-text {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--input {
        border: 1px solid u.$shuttle-gray;
        border-radius: 10px;
        padding: 8px 16px;
      }
    }
  }
}
