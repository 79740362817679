@use "../utils/index" as u;

.reportarForm {
  width: 90%;
  min-height: 60vh;
  margin: 16px auto;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &__checkbox {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__checkbox h3 {
    margin-bottom: 8px;
  }

  &__checkbox label {
    display: block;
    margin-right: 0;
  }

  &__input-group {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__comentario {
    width: 100%;
    margin-bottom: 32px;
  }

  &__error {
    margin-bottom: 16px;
  }

  &__buttons {
    background-color: white;
    position: relative;
    z-index: 20;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    &--fromStatus {
      @extend .reportarForm__buttons;
      justify-content: space-between;

      &__formButtons {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

    &--enviar {
      margin-right: 16px;
    }

    &--cancelar {
      background-color: inherit !important;
      border: 1px solid u.$shuttle-gray;
      color: u.$shuttle-gray !important;
    }
  }
}

/*
    Checkbox
*/

//tilde
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: u.$dodger-blue;
}

//recuadro
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: u.$dodger-blue !important;
  border-radius: 4px !important;
}

/*
    Textarea
*/

.reportarForm-textarea {
  width: inherit;
  height: 150px;
  resize: none;
  border-radius: 10px;
  border: 1px solid u.$catskill-white;
  padding: 8px 16px;
}

/*
  Error
*/
.reportarForm .inputTextErrMsg {
  font-size: 1rem;
  text-align: end;
}
