/*
    Primary Colors
*/
$shark: #181c21;
$dodger-blue: #475fff;
$white: #fff;

/*
    Secondary Colors
*/
$gulf-blue: #001063;
$azure: #0067dd;
$danube: #88bbff;

/*
    Status Colors
*/
$keppel: #008800;
$keppel-light: #0088005c;
$copperfield: #d51f11;
$copperfield-light: #d51f115c;
$iceberg: #e7f6f3;
$peach-schnapps: #fff0e7;
$com: #ecba09;
$buttermilk: #fff8d0;

/*
    Neutral Colors
*/
$shuttle-gray: #5f6877;
$catskill-white: #e5ebf3;
$zircon: #fbfcff;
$mauve: #d6a0ff;
$lavender: #cbbaff;
$light-gray: #c1c0c9;

.color--shark {
  color: $shark;
}

.color--dodger-blue {
  color: $dodger-blue;
}

.bgColor--dodger-blue {
  background-color: $dodger-blue;
}

.color--white {
  color: $white;
}

.color--gulf-blue {
  color: $gulf-blue;
}

.color--azure {
  color: $azure;
}

.color--danube {
  color: $danube;
}

.color--com {
  color: $com;
}

.color--keppel {
  color: $keppel;
}

.color--keppel-light {
  color: $keppel-light;
}

.color--copperfield {
  color: $copperfield;
}

.color--copperfield-light {
  color: $copperfield-light;
}

.color--iceberg {
  color: $iceberg;
}

.color--peach-schnapps {
  color: $peach-schnapps;
}

.color--shuttle-gray {
  color: $shuttle-gray;
}

.color--catskill-white {
  color: $catskill-white;
}

.color--zircon {
  color: $zircon;
}
