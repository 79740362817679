@use "../utils" as u;

.configBrand {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;

  &__sub-title {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__container {
      display: flex;
      gap: 16px;

      &__img {
        width: u.rem(77);
        height: u.rem(77);
        border: 1px solid u.$catskill-white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
          width: u.rem(77);
          color: u.$catskill-white;
        }

        &__icon::before {
          margin: 0;
          font-size: u.rem(77);
        }
      }

      &__btn-container {
        padding: 4px 0;
        height: u.rem(77);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__color-container {
      display: flex;
      gap: 16px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__text-container {
      display: flex;
      gap: 16px;
    }
  }
}
