@use "../utils" as u;

.selectStatus {
  width: 400px;
  padding: 24px;
  border: 1px solid u.$catskill-white;
  border-radius: 10px;
  background-color: u.$white;
  position: absolute;
  right: -15px;
  top: u.rem(45);
  z-index: 10;
}
