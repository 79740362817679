@use "../utils" as u;

.json-report {
  display: flex;
  width: 95%;
  height: 70%;
  // max-height: 83vh;
  margin: 16px auto;
  background-color: u.$zircon;
  padding: 8px 4px;
  overflow-y: scroll;

  &__btn {
    align-self: flex-end;
    display: flex;
    margin-right: 10px;
    align-items: center;
  }

  &__btn > div::before {
    margin-right: 3px;
    font-size: u.rem(18);
  }

  &__json-container {
    width: 90%;
    height: 95%;
    overflow-wrap: break-word;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__json-text {
    white-space: pre-wrap;
  }
}
