@use "../utils" as u;

.button {
  border-radius: 52px;
  padding: 12px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: u.$white;
  background-color: u.$dodger-blue;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09),
    0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.5s ease;

  &--disabled {
    @extend .button;
    background-color: u.$mauve;
    cursor: context-menu;
    transition: background-color 0.5s ease;
  }

  &__icon {
    width: u.rem(20);
    height: u.rem(20);
    font-size: u.rem(20);
  }

  &__icon::before {
    margin: 0;
    width: u.rem(20);
    height: u.rem(20);
    font-size: u.rem(20);
  }

  &__relative-container {
    width: fit-content;
    height: fit-content;
    position: relative;
  }
}
