@use "../utils" as u;

.colorPicker {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__container {
    min-height: 50px;
    min-width: 145px;
    border: 1px solid u.$catskill-white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }

  &__sample {
    width: 35%;
    height: 100%;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
  }

  &__picker-container {
    position: relative;
  }

  &__picker-container .sketch-picker {
    position: absolute;
    z-index: 5;
  }
}
