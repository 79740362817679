@use "../utils" as u;

.billing-card {
  padding: 24px;

  &__title-container {
    display: flex;
    justify-content: space-between;

    &__title {
      height: u.rem(50);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__separator {
    border: 1px solid u.$catskill-white;
    margin: 16px 0;
  }

  &__services {
    height: u.rem(80);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    &--paid {
      @extend .billing-card__services;
      height: u.rem(50);
    }

    &__service {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &__service .icon::before {
      margin-right: 8px !important;
    }
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;

    &__btn {
      width: u.rem(85);
      height: u.rem(36);
      padding: 9px 12px;
      border-radius: 50px;
      border: 1px solid u.$shuttle-gray;
      color: u.$shuttle-gray;
    }
  }
}
