@use "../utils/" as u;

/* use a hand cursor instead of arrow for the action buttons */
.fileUploader .filepond--file-action-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
  color: u.$white;
}

/* the text color of the drop label*/
.fileUploader .filepond--drop-label {
  color: u.$shark;
  min-height: 235px !important;
}

/* underline color for "Browse" button */
.fileUploader .filepond--label-action {
  color: u.$dodger-blue;
  text-decoration-color: u.$dodger-blue;
}

/* the background color of the filepond drop area */
.fileUploader .filepond--panel-root {
  background-color: u.$white;
}

/* the background color of the file and file panel (used when dropping an image) */
.fileUploader .filepond--item-panel {
  background-color: u.$white;
}

/* the background color of the drop circle */
.fileUploader .filepond--drip-blob {
  background-color: u.$danube !important;
}

/* the color of the focus ring */
.fileUploader .filepond--file-action-button:hover,
.fileUploader .filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em u.$catskill-white !important;
}

/* the text color of the file status and info labels */
.fileUploader .filepond--file {
  color: u.$shark;
  background-color: u.$white;
  font-size: u.rem(14);
  line-height: u.rem(18);
}

/* error state color */
.fileUploader [data-filepond-item-state*="error"] .filepond--file,
.fileUploader [data-filepond-item-state*="invalid"] .filepond--file,
.fileUploader [data-filepond-item-state*="load-invalid"] .filepond--file {
  border: 1px solid u.$copperfield;
}

.fileUploader
  [data-filepond-item-state="processing-complete"]
  .filepond--item-panel {
  border: 1px solid u.$keppel;
}

/* limiting height */
.fileUploader .filepond--root {
  font-family: u.$font_regular;
  min-height: 235px;
  margin-bottom: 0 !important;
  max-height: 700px;
  overflow: auto;
}

.fileUploader .filepond--wrapper {
  min-height: 235px;
}

/*Setting container styles*/
.fileUploader {
  border: 2px dashed u.$shuttle-gray;
  border-radius: 10px;
}

.fileUploader-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.fileUploader--error {
  @extend .fileUploader;
  border: 2px dashed u.$copperfield;
}
