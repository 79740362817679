@use "../utils" as u;

.configIdentity {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__sub-title {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px 32px;

    &__form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__form-group > p {
      padding: 0 32px;
    }

    &__sub-group {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0 32px;
    }
  }

  &__warning {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }

  &__warning > .icon-error-relleno {
    width: u.rem(18);
    height: u.rem(18);
  }

  &__warning > .icon-error-relleno::before {
    margin: 0;
    display: block;
    font-size: u.rem(18);
    width: u.rem(18);
    height: u.rem(18);
  }
}
