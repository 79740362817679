@use "../utils/index" as u;

.iconInfo {
  display: inline-block;
  position: relative;
  width: fit-content;
  height: u.rem(18);

  &--icon {
    display: inline-block;
    width: u.rem(18);
    height: u.rem(18);
    position: relative;
    cursor: pointer;
  }

  &--icon::before {
    font-size: u.rem(18);
  }

  &--text {
    width: 100px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 30px;
    background-color: u.$gulf-blue;
    color: u.$zircon;
    padding: 10px;
    z-index: 5;
  }
}
