@use "../utils" as u;

.publicLayout {
  padding: 32px 75px;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: u.$white;
  overflow: hidden;

  &__container {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;

    &--image {
      background-color: u.$dodger-blue;
      color: u.$zircon;
      border-radius: 32px;
    }
    &--facekey-image {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }

  &__form {
    width: 100%;
    height: 100%;
    padding: 32px 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--signin {
      @extend .publicLayout__form;
      padding-left: 145px;
      justify-content: center;
    }

    @media (max-width: 1440px) {
      &--signin {
        padding-left: 100px;
      }
    }

    &--logo {
      width: 12.5rem;
      margin-bottom: 32px;
    }

    &__container {
      overflow-y: scroll;
      padding-right: 15px;
      display: flex;
      flex-direction: column;
      gap: 32px;

      &--signin {
        @extend .publicLayout__form__container;
        width: 65%;
        overflow-y: visible;
        min-width: 380px;
      }
    }
  }

  &__image {
    width: 85%;
    height: 100%;
    padding: 65px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    &--title {
      width: 90%;
      max-width: 450px;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    &--title b {
      font-family: u.$font_bold;
    }

    &--images {
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  &__image img {
    width: 100%;
  }
}
