@use "../utils" as u;

.langSelector {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px 12px;
  background: url("../icons/images/icon-down.svg") no-repeat right 10px center;
  background-color: u.$white;
  background-size: 20px 20px;
  color: u.$shark;
  border: 1px solid u.$shuttle-gray;
  border-radius: 20px;
  min-width: 100px;
  margin-left: 24px;
}

.langSelector option {
  padding: 8px 12px;
}

.langSelector:focus-visible {
  outline: none;
}
