.showImage {
  &__zoomContainer {
    width: 100%;
    max-height: 675px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__zoomTools {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 9999;
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  &__img {
    width: 100%;
  }
}
