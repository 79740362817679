@use "../utils/index" as u;

.textDataResumen__container {
  width: 100%;
  padding: 8px 16px;
  margin: 8px auto;
  display: flex;
  justify-content: space-between;

  &--network-report {
    @extend .textDataResumen__container;
    margin: 0;
    padding: 12px 16px;
    gap: 8px;
  }

  &--user-id {
    @extend .textDataResumen__container;
    padding: 0;
    width: 100%;
    margin: 0;

    &__form {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &__form-elements {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--edit {
        @extend .textDataResumen__container--user-id__form-elements;
        justify-content: flex-start;
        gap: 8px;
      }

      &--edit > span {
        display: inline-block;
        min-width: 50%;
      }
    }

    &__form-input {
      color: u.$shuttle-gray;
      border: none;
      outline: none;
      width: 50%;
    }

    &__form-input:focus {
      outline: none;
      color: u.$shark;
    }

    &__btn {
      color: u.$dodger-blue;
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }
}
