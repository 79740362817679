.flags-component {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  &__flags {
    font-size: 1.5rem;
  }
}
