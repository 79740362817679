@use "../utils" as u;

.linkPage {
  width: 100%;
  min-height: 85vh;
  padding: 16px;
  border: 1px solid u.$catskill-white;
  border-radius: 10px;
  background-color: u.$white;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__configuration {
    width: 100%;
    min-height: 85vh;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &__menu,
    &__preview {
      width: 50%;
      padding: 24px;
      border: 1px solid u.$catskill-white;
      border-radius: 10px;
      min-height: inherit;
      background-color: u.$white;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__btn-container {
        align-self: flex-end;
        display: flex;
        align-items: center;
        gap: 32px;
      }

      &__header {
        display: flex;
        align-items: center;
        gap: 16px;

        &__btn {
          width: u.rem(24);
          height: u.rem(24);
          font-size: u.rem(24);
        }

        &__btn::before {
          display: inline-block;
          margin: 0;
          width: u.rem(24);
          height: u.rem(24);
          font-size: u.rem(24);
        }
      }
    }
  }

  &__option {
    width: 100%;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid u.$catskill-white;
    cursor: pointer;

    &__btn {
      width: u.rem(24);
      height: u.rem(24);
      font-size: u.rem(24);
    }

    &__btn > span::before {
      display: inline-block;
      margin: 0;
      width: u.rem(24);
      height: u.rem(24);
      font-size: u.rem(24);
    }
  }

  &__options__dropdown {
    width: 215px !important;

    &__icon {
      width: u.rem(16);
      height: u.rem(16);
      margin-right: 6px;
    }

    &__icon::before {
      font-size: u.rem(16);
    }

    &__content {
      background-color: u.$white;
      gap: 6px !important;
    }
  }

  &__badge {
    padding: 2px 4px;
    border-radius: 4px;

    &--active {
      @extend .linkPage__badge;
      background-color: u.$iceberg;
      color: u.$keppel;
    }

    &--inactive {
      @extend .linkPage__badge;
      background-color: u.$white;
      color: u.$shuttle-gray;
    }
  }
}
