@use "../utils" as u;

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;

  &__option {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__input {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__text {
    padding-left: 24px;
  }
}
