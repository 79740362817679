.generateLinkForm {
  min-height: inherit;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__btn-container {
    width: fit-content;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 32px;
  }
}

.generateLinkForm > .textInputComponent,
.generateLinkForm .custom-label,
.generateLinkForm .custom-input,
.generateLinkForm .custom-input .MuiOutlinedInput-root fieldset {
  max-width: none;
}
