@use "../utils" as u;

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 2px 4px;

  &__icon {
    width: u.rem(18);
    height: u.rem(18);
    font-size: u.rem(18);
  }

  &__icon::before {
    display: inherit;
    font-size: inherit;
    margin: 0;
  }

  &__text {
    font-family: u.$font_regular;
    letter-spacing: 0;
    font-size: u.rem(14);
    line-height: u.rem(18);
  }
}

.billingBadge {
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;

  &__circle {
    width: u.rem(6.8);
    height: u.rem(6.8);
    border-radius: u.rem(6.8);

    &--active {
      @extend .billingBadge__circle;
      background-color: u.$keppel;
    }

    &--inactive {
      @extend .billingBadge__circle;
      background-color: u.$copperfield;
    }
  }

  &--active {
    @extend .billingBadge;
    color: u.$keppel;
    border: 1px solid u.$keppel;
  }

  &--inactive {
    @extend .billingBadge;
    color: u.$copperfield;
    border: 1px solid u.$copperfield;
  }
}
