@use "../utils" as u;

.changePassForm {
  height: 30.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;

  &__loader-container {
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: -75px;
    top: -20px;
  }

  &__form {
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__buttons {
      margin-top: 24px;
      display: flex;
      gap: 12px;
      position: relative;
    }
  }

  &__form > .textInputComponent,
  &__form .custom-input,
  &__form .custom-label,
  &__form .custom-input .MuiInputBase-root,
  &__form .custom-input .MuiOutlinedInput-root fieldset {
    max-width: none !important;
  }
}
