@use "../utils" as u;

.errorComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  max-width: 320px;
  margin: 50px auto;

  &__img {
    width: 50%;
    max-width: 105px;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  &__title {
    text-align: center;
  }

  &__header-icon {
    width: u.rem(24);
    height: u.rem(24);
    font-size: u.rem(24);
    display: flex;
    align-items: flex-start;
  }

  &__header-icon > span::before {
    margin: 0;
    font-size: u.rem(24);
  }
}
