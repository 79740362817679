@use "../utils/" as u;

.commandPanelRules {
  padding: 24px;
  min-height: 475px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  background-color: u.$white;

  &__title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: u.$shark;
  }

  &__btn {
    width: u.rem(24);
    height: u.rem(24);
  }

  &__btn::before {
    margin: 0;
    font-size: u.rem(24);
  }

  &__table-container {
    width: 100%;
    min-height: 275px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__table {
    align-self: flex-start;
    width: 100%;
    border-spacing: 0;
  }

  &__table td {
    padding: 16px 8px;
    max-width: 370px;
  }

  &__table th {
    font-weight: 400;
    border-top: 1px solid u.$catskill-white;
    border-bottom: 1px solid u.$catskill-white;
  }

  &__table-title {
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__table-btn {
    width: u.rem(18);
    height: u.rem(18);
  }

  &__table-btn::before {
    margin: 0;
    font-size: u.rem(18);
  }
}
