@use "../utils" as u;

.billing {
  width: 100%;
  height: 100%;

  &__header {
    &__buttons-container {
      width: u.rem(310);
      max-width: 550px;
      display: flex;
      justify-content: space-between;
    }

    &__btn {
      width: auto !important;
      color: u.$shark !important;
    }
  }

  &__buttons {
    margin-top: 4px;
  }

  &__pago-container {
    margin-top: 16px;
    width: 48%;
    height: fit-content;
    min-height: 245px;

    &--50 {
      width: 100%;
      height: 45%;
      min-height: 145px;
    }

    &--doble {
      @extend .billing__pago-container;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 35vh;
      min-height: 320px;
    }
  }

  &__pago-container .iconInfo--icon::before {
    font-size: u.rem(14);
  }

  &__table-container {
    margin-top: 20px;
    height: 50vh;
  }

  &__table {
    &--tr th:nth-of-type(1),
    &--tr th:nth-of-type(3) {
      width: 20% !important;
    }

    &--tr th:nth-of-type(2),
    &--tr th:nth-of-type(4) {
      width: 15% !important;
    }
  }

  &__download {
    text-decoration: none;
    padding: 4px 8px !important;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &__download span:nth-of-type(1)::before {
    margin: 0 !important;
  }

  &__totals-container {
    width: 100%;
  }
}
