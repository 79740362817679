@use "../utils" as u;

.fontPicker {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__dropdown-button {
    border-radius: 4px !important;
    box-shadow: none !important;
  }

  &__dropdown-button button {
    border-radius: 4px !important;
    border: 1px solid u.$catskill-white !important;
  }

  &__dropdown-button button,
  &__dropdown-button ul {
    background-color: u.$white !important;
  }

  &__dropdown-button .dropdown-font-family {
    font-family: u.$font_regular !important;
    letter-spacing: 0 !important;
    font-size: u.rem(16) !important;
    line-height: u.rem(22) !important;
    color: u.$shuttle-gray !important;
  }

  &__dropdown-button .dropdown-icon::before {
    margin: 0 !important;
    color: u.$shuttle-gray !important;
  }

  &__dropdown-button .dropdown-icon.finished::before {
    border-top: 6px solid u.$shuttle-gray !important;
  }
}

div[id^="font-picker"] ul li button.active-font,
div[id^="font-picker"] ul li button:hover {
  background-color: #5f687720 !important;
}
