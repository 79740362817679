@use "../utils/index" as u;

.selectDate {
  width: fit-content;
  display: inline-block;
  position: relative;

  &--btn {
    border: 1px solid u.$shark;
    border-radius: 50px;
    padding: 8px 12px;
    height: 30px;
    min-width: 100px;
    margin-right: 8px;
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  &__container {
    &--months {
      border: 1px solid u.$dodger-blue;
      border-radius: 4px;
      min-width: 50px;
      max-height: 190px;
      overflow-y: auto;
      padding: 8px;
      background-color: u.$zircon;
      position: absolute;
      top: 35px;
      left: 16px;
      z-index: 5;

      &__month {
        text-transform: capitalize;
        width: 100%;
        height: 1.5rem;
        margin: 4px auto;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }

      &__month div {
        width: 1rem;
        height: 1rem;
      }
    }

    &__days {
      width: 80px;
      height: 190px;
      position: absolute;
      top: 35px;
      left: 90px;
    }

    &--days {
      width: 80px;
      max-height: 190px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      background-color: u.$zircon;
      border: 1px solid u.$dodger-blue;
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 35px;
      padding: 8px;
      z-index: 10;

      &--btn {
        width: 100%;
        height: 1.5rem;
        cursor: pointer;
      }
    }
  }
}

.selectDate--btn div {
  width: 1.5rem;
  height: 1.5rem;
}

.selectDate--btn div::before {
  font-size: 1.5rem;
}

.underline-0,
.underline-1,
.underline-2,
.underline-3,
.underline-4,
.underline-5,
.underline-6,
.underline-7,
.underline-8,
.underline-9,
.underline-10,
.underline-11 {
  border-bottom: 2px solid u.$shark;
}

.rdrDayToday .rdrDayNumber span::after {
  background: inherit !important;
}
