@use "../utils/index" as u;

.textInputComponent {
  width: 100%;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font-size: 0.8rem;
  position: relative;

  &--title {
    width: fit-content;
    display: flex;
  }

  &--title span {
    margin-right: 5px;
  }

  &--label {
    margin-left: 0 !important;
  }
}

.MuiFormControlLabel-root,
.MuiFormLabel-root,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-outlined,
.MuiFormLabel-colorPrimary,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-outlined,
.MuiInputBase-input,
.MuiOutlinedInput-input,
.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-formControl {
  letter-spacing: 0 !important;
}

/*
    Estilos de borde
*/
.custom-input,
.custom-label {
  padding: 0;
  margin-right: 0 !important;
}

fieldset {
  margin-inline-start: 0 !important;
  margin-block-start: 0 !important;
  padding-block-start: 0 !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  padding-block-end: 0 !important;
}

.custom-input .MuiOutlinedInput-root fieldset {
  border-radius: 4px !important;
  border-bottom-color: u.$catskill-white;
  border-top-color: u.$catskill-white;
  border-left-color: u.$catskill-white;
  border-right-color: u.$catskill-white;
}

.custom-input .MuiInputBase-root {
  min-height: 2.5rem;
  border-bottom-color: u.$catskill-white;
  border-top-color: u.$catskill-white;
  border-left-color: u.$catskill-white;
  border-right-color: u.$catskill-white;
}

.custom-input .MuiFormLabel-root {
  top: -8px;
}

.custom-input .MuiOutlinedInput-root:hover fieldset {
  border-color: u.$shuttle-gray;
}

.custom-input .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: u.$dodger-blue;
}

.custom-input.valid-input .MuiOutlinedInput-root fieldset {
  border-color: u.$keppel;
}

.custom-input.invalid-input .MuiOutlinedInput-root fieldset {
  border-color: u.$copperfield;
}

.custom-input .MuiInputBase-input {
  padding: 4px 8px;
}

/*
    Estilos de Mensaje
*/

.slideContainer {
  height: 30px;
  margin-top: 6px;

  &--no-margin {
    height: 30px;
  }
}

.inputTextErrMsg {
  color: u.$copperfield;
}

/*
  File Input
*/

.fileInputLabel {
  width: 100%;

  &--icon .MuiButtonBase-root {
    color: u.$dodger-blue;
  }

  &--icon .MuiButtonBase-root div::before,
  &--icon--valid .MuiButtonBase-root div::before,
  &--icon--invalid .MuiButtonBase-root div::before {
    margin: 0 !important;
  }

  &--icon--valid .MuiButtonBase-root {
    border-color: u.$keppel;
  }

  &--icon--invalid .MuiButtonBase-root {
    border-color: u.$copperfield;
  }
}

.custom-input.custom-label.fileInputLabel {
  min-height: 2.5rem;
}

.fileInputLabel .MuiInputBase-root {
  padding: 0;
}

.fileInputLabel--selected .MuiOutlinedInput-root fieldset {
  border: 1px solid u.$dodger-blue;
}

.fileInputLabel--selected .MuiTypography-root {
  width: 80% !important;
}

.fileInputLabel .MuiButtonBase-root {
  position: absolute;
  right: 10px;
}

.fileInputLabel .MuiTypography-root {
  position: absolute;
  left: 16px;
  display: inline-block;
  width: 75%;
  color: u.$shark;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteDefaultInputFileStyle input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/*
  Text with List
*/

.inputAuto {
  position: relative;
}

.inputAuto--title {
  width: 100%;
}

/*
  Dropdown
*/
.inputDropdown .MuiInputBase-root {
  padding: 0;
}

.inputAuto--dropdown {
  height: 2.5rem !important;
  max-height: none !important;
  padding: 10px 8px;
  width: 90px;
  background-color: u.$catskill-white;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  justify-content: center;
  gap: 6px;
  cursor: pointer;

  &__label .MuiInputLabel-root {
    left: 60px;
  }

  &--flag {
    width: u.rem(20) !important;
    width: u.rem(20) !important;
    margin: 0 !important;
  }

  &--icon {
    font-size: u.rem(20) !important;
    font-weight: 700;
  }

  &--icon::before {
    margin: 0 !important;
    font-size: u.rem(20) !important;
    font-weight: 700;
  }

  &__content--preFix {
    color: u.$shuttle-gray;
    margin-left: 4px;
  }
}
