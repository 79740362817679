.searchDetails {
  scroll-behaviour: smooth;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
