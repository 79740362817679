@use "../utils" as u;

.modal {
  height: 100dvh;
  width: 100dvw;
  background-color: rgba(95, 104, 119, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;

  &--logout {
    width: 100%;
    left: 0;
  }

  &--center-container {
    min-width: 30rem;
    min-height: 20rem;
    border: 1px solid u.$catskill-white;
    background-color: u.$zircon;
    border-radius: 10px;

    &__container {
      min-height: 20rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 10px;

      &--icon::before {
        font-size: 5rem;
        margin: 0 !important;
      }

      &--text {
        text-align: center;
        color: u.$shuttle-gray;
        width: 55%;
      }

      &__buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 24px;
        position: relative;
      }

      &__buttons > .lds-ring-small {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 80px;
        top: -2px;
      }

      &__buttons .ringFragment {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__csv {
    height: 20rem;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--send {
      @extend .modal__csv;
      height: auto;
      max-width: 530px;
      justify-content: center;
      padding: 24px;
      gap: 24px;
    }

    &--send p {
      text-align: center;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__btn {
      width: u.rem(24);
      height: u.rem(24);
      font-size: u.rem(24);
    }

    &__btn::before {
      margin: 0;
      display: inline-block;
      width: u.rem(24);
      height: u.rem(24);
      font-size: u.rem(24);
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__calendar {
      width: 100%;
      justify-content: flex-start;

      &__btn {
        width: 100%;
        border: 1px solid u.$catskill-white;
        gap: 12px;
        justify-content: flex-start;
      }

      &__picker {
        top: 0;
        right: 75px;
        left: 0;

        &__btn {
          top: 400px;
          right: 75px;
        }
      }
    }

    &__sendHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      &__icon {
        width: u.rem(64);
        height: u.rem(64);
        font-size: u.rem(64);
        color: u.$shark;
      }

      &__icon::before {
        display: inline-block;
        margin: 0;
        width: u.rem(64);
        height: u.rem(64);
        font-size: u.rem(64);
      }
    }
  }

  &__signup {
    overflow-x: hidden;
    display: flex;
    padding: 1.5rem;
    width: 55dvw;
    height: 80vh;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    border-radius: 0.625rem;
    background-color: #fff;

    &__overflow {
      overflow-y: auto;
    }

    &__text {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__text ol,
    &__text ul {
      padding-left: 20px;
    }

    &__btn {
      align-self: flex-end;
    }
  }
}

.sendMailModal {
  background-color: rgba(95, 104, 119, 0.5);

  &--center-container {
    &__container {
      &--text {
        padding-bottom: 1rem;
      }
    }
  }
}

.changePassModal {
  &--icon {
    color: u.$keppel;
  }

  &--icon::before {
    margin-right: 0 !important;
    font-size: 5rem;
  }
}

.addUserModal {
  padding: 32px;

  &__form > .textInputComponent,
  &__form .custom-input,
  &__form .custom-label,
  &__form .custom-input .MuiInputBase-root,
  &__form .custom-input .MuiOutlinedInput-root fieldset {
    max-width: none !important;
  }

  &__form {
    &--info {
      width: 7.5rem;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    &--info .iconInfo {
      height: 18px;
    }

    &--dropdown {
      width: 100%;
      height: 50px;
      border: 1px solid u.$shuttle-gray;
      border-radius: 10px;
      padding: 8px 16px;
      margin-bottom: 25px;
    }

    &--dropdown .team__accounts-container--account-btn {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: none;
    }

    &--dropdown .autoCompleteSelect {
      width: 108% !important;
      max-width: none;

      &__content {
        height: auto;
      }
    }

    &--dropdown .team--dropdown__content--element {
      margin-bottom: 5px;
    }

    &--dropdown .icon-expand-menos::before,
    &--dropdown .icon-down::before {
      font-size: 0.875rem;
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    gap: 10px;
  }

  &__buttons-container .lds-ring-small {
    position: absolute;
    right: 205px;
    top: -2px;
    width: 40px;
    height: 40px;
  }

  &__buttons-container .ringFragment {
    width: 30px;
    height: 30px;
  }
}
