@use "../utils/index" as u;

.searchBar {
  position: relative;
  width: 80%;
  // max-width: 500px;
  height: 34px;
  border: 1px solid u.$catskill-white;
  border-radius: 16px;

  &__icon {
    background-color: inherit;
    border: none;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
  }

  &__input {
    display: inline-block;
    width: 85%;
    height: 30px;
    padding: 8px;
    position: absolute;
    top: 1px;
    left: 30px;
    border: none;
  }

  &__input:focus {
    border: none;
    outline: none;
  }
}
