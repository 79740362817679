@use "../utils/index" as u;
@import "variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3on7nx");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3on7nx#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3on7nx")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3on7nx")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3on7nx##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    margin-right: $me;
  }
}

.icon-Data-Enrichment {
  &:before {
    content: $icon-Data-Enrichment;
  }
}
.icon-Face-Analysis {
  &:before {
    content: $icon-Face-Analysis;
  }
}
.icon-Frame {
  &:before {
    content: $icon-Frame;
  }
}
.icon-abrir-menu {
  &:before {
    content: $icon-abrir-menu;
  }
}
.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-adjuntar {
  &:before {
    content: $icon-adjuntar;
  }
}
.icon-alerta {
  &:before {
    content: $icon-alerta;
  }
}
.icon-alerta-off {
  &:before {
    content: $icon-alerta-off;
  }
}
.icon-alerta-on {
  &:before {
    content: $icon-alerta-on;
  }
}
.icon-amenaza {
  &:before {
    content: $icon-amenaza;
  }
}
.icon-anterior {
  &:before {
    content: $icon-anterior;
  }
}
.icon-arrastrar {
  &:before {
    content: $icon-arrastrar;
    margin: 0 !important;
  }
}
.icon-buscar {
  &:before {
    content: $icon-buscar;
    margin: 0;
  }
}
.icon-calendario {
  &:before {
    content: $icon-calendario;
  }
}
.icon-candado {
  &:before {
    content: $icon-candado;
  }
}
.icon-cerrar-menu {
  &:before {
    content: $icon-cerrar-menu;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-checklist {
  &:before {
    content: $icon-checklist;
  }
}
.icon-check-plano {
  &:before {
    content: $icon-check-plano;
  }
}
.icon-check-relleno {
  &:before {
    content: $icon-check-relleno;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-comentario {
  &:before {
    content: $icon-comentario;
  }
}
.icon-contrato {
  &:before {
    content: $icon-contrato;
  }
}
.icon-copy {
  &:before {
    content: $icon-copy;
  }
}
.icon-correo {
  &:before {
    content: $icon-correo;
    margin: 0;
  }
}
.icon-CSV {
  &:before {
    content: $icon-CSV;
  }
}
.icon-descargar {
  &:before {
    content: $icon-descargar;
  }
}
.icon-desktop {
  &:before {
    content: $icon-desktop;
  }
}
.icon-down {
  &:before {
    content: $icon-down;
  }
}
.icon-editar {
  &:before {
    content: $icon-editar;
    margin: 0;
  }
}
.icon-empresa {
  &:before {
    content: $icon-empresa;
  }
}
.icon-empty {
  &:before {
    content: $icon-empty;
  }
}
.icon-enviar {
  &:before {
    content: $icon-enviar;
  }
}
.icon-error {
  &:before {
    content: $icon-error;
  }
}
.icon-error-relleno {
  &:before {
    content: $icon-error-relleno;
  }
}
.icon-expand {
  &:before {
    content: $icon-expand;
  }
}
.icon-expand-menos {
  &:before {
    content: $icon-expand-menos;
  }
}
.icon-filtros {
  &:before {
    content: $icon-filtros;
  }
}
.icon-flag {
  &:before {
    content: $icon-flag;
  }
}
.icon-flag-fill {
  &:before {
    content: $icon-flag-fill;
  }
}
.icon-flecha {
  &:before {
    content: $icon-flecha;
  }
}
.icon-flecha-izquierda {
  &:before {
    content: $icon-flecha-izquierda;
  }
}
.icon-guardar {
  &:before {
    content: $icon-guardar;
    margin: 0;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-lista {
  &:before {
    content: $icon-lista;
  }
}
.icon-menu-bill {
  &:before {
    content: $icon-menu-bill;
  }
}
.icon-menu-busq {
  &:before {
    content: $icon-menu-busq;
  }
}
.icon-menu-config {
  &:before {
    content: $icon-menu-config;
  }
}
.icon-menu-cons {
  &:before {
    content: $icon-menu-cons;
  }
}
.icon-menu-contact {
  &:before {
    content: $icon-menu-contact;
  }
}
.icon-menu-dash {
  &:before {
    content: $icon-menu-dash;
  }
}
.icon-menu-docs {
  &:before {
    content: $icon-menu-docs;
  }
}
.icon-menu-noti {
  &:before {
    content: $icon-menu-noti;
  }
}
.icon-menu-out {
  &:before {
    content: $icon-menu-out;
  }
}
.icon-menu-perfil {
  &:before {
    content: $icon-menu-perfil;
  }
}
.icon-menu-team {
  &:before {
    content: $icon-menu-team;
  }
}
.icon-mostrar {
  &:before {
    content: $icon-mostrar;
    margin: 0;
  }
}
.icon-ocultar {
  &:before {
    content: $icon-ocultar;
    margin: 0;
  }
}
.icon-opciones {
  &:before {
    content: $icon-opciones;
  }
}
.icon-persona {
  &:before {
    content: $icon-persona;
  }
}
.icon-quitar {
  &:before {
    content: $icon-quitar;
  }
}
.icon-revisa {
  &:before {
    content: $icon-revisa;
  }
}
.icon-servicios {
  &:before {
    content: $icon-servicios;
  }
}
.icon-sheets {
  &:before {
    content: $icon-sheets;
  }
}
.icon-siguiente {
  &:before {
    content: $icon-siguiente;
  }
}
.icon-tarea {
  &:before {
    content: $icon-tarea;
  }
}
.icon-ID-Analysis {
  &:before {
    content: $icon-ID-Analysis;
  }
}
.icon-img {
  &:before {
    content: $icon-img;
  }
}
.icon-Linea {
  &:before {
    content: $icon-Linea;
  }
}
.icon-menu-perfil-2 {
  &:before {
    content: $icon-menu-perfil-2;
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile;
  }
}
.icon-opciones {
  &:before {
    content: $icon-opciones;
  }
}
.icon-tiempo {
  &:before {
    content: $icon-tiempo;
  }
}

.icon-heading {
  font-size: u.rem(20);
  line-height: u.rem(26);
  letter-spacing: -0.05rem;
}
