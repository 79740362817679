@font-face {
  font-family: "DM sans";
  src: url("./fonts/DMSans-Regular.ttf");
  font-weight: 400;
  font-style: "regular";
}

@font-face {
  font-family: "DM sans Medium";
  src: url("./fonts/DMSans-Medium.ttf");
  font-weight: 500;
  font-style: "medium";
}

@font-face {
  font-family: "DM sans Bold";
  src: url("./fonts/DMSans-Bold.ttf");
  font-weight: 700;
  font-style: "bold";
}

/*
  Variables
*/
$font_regular: "DM Sans";
$font_medium: "DM Sans Medium";
$font_bold: "DM Sans Bold";
