@use "../utils" as u;

.errorSlide {
  width: 100%;
  height: 32px;
  margin-top: 12px;
  position: relative;
  z-index: 15;

  &__busqueda {
    margin-top: 8px !important;
    font-size: 1.25rem !important;
    line-height: 1.625rem !important;
  }
}
