$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

/*
	Margenes
*/

$me: 8px;

/*
    Iconos
*/

$icon-Data-Enrichment: "\e900";
$icon-Face-Analysis: "\e949";
$icon-Frame: "\e901";
$icon-abrir-menu: "\e902";
$icon-add: "\e903";
$icon-adjuntar: "\e904";
$icon-alerta: "\e905";
$icon-alerta-off: "\e906";
$icon-alerta-on: "\e907";
$icon-amenaza: "\e908";
$icon-anterior: "\e909";
$icon-arrastrar: "\e90a";
$icon-buscar: "\e90b";
$icon-calendario: "\e90c";
$icon-candado: "\e90d";
$icon-cerrar-menu: "\e90e";
$icon-check: "\e90f";
$icon-checklist: "\e910";
$icon-check-plano: "\e911";
$icon-check-relleno: "\e912";
$icon-clock: "\e913";
$icon-close: "\e914";
$icon-comentario: "\e915";
$icon-contrato: "\e916";
$icon-copy: "\e917";
$icon-correo: "\e918";
$icon-CSV: "\e919";
$icon-descargar: "\e91a";
$icon-desktop: "\e91b";
$icon-down: "\e91c";
$icon-editar: "\e91d";
$icon-empresa: "\e91e";
$icon-empty: "\e91f";
$icon-enviar: "\e920";
$icon-error: "\e921";
$icon-error-relleno: "\e922";
$icon-expand: "\e923";
$icon-expand-menos: "\e924";
$icon-filtros: "\e925";
$icon-flag: "\e926";
$icon-flag-fill: "\e927";
$icon-flecha: "\e928";
$icon-flecha-izquierda: "\e929";
$icon-guardar: "\e92a";
$icon-info: "\e92b";
$icon-lista: "\e92c";
$icon-menu-bill: "\e92d";
$icon-menu-busq: "\e92e";
$icon-menu-config: "\e92f";
$icon-menu-cons: "\e930";
$icon-menu-contact: "\e931";
$icon-menu-dash: "\e932";
$icon-menu-docs: "\e933";
$icon-menu-noti: "\e934";
$icon-menu-out: "\e935";
$icon-menu-perfil-2: "\e936";
$icon-menu-team: "\e937";
$icon-mostrar: "\e938";
$icon-ocultar: "\e939";
$icon-opciones: "\e93a";
$icon-persona: "\e93b";
$icon-quitar: "\e93c";
$icon-revisa: "\e93d";
$icon-servicios: "\e93e";
$icon-sheets: "\e93f";
$icon-siguiente: "\e940";
$icon-tarea: "\e941";
$icon-ID-Analysis: "\e942";
$icon-img: "\e943";
$icon-Linea: "\e944";
$icon-menu-perfil: "\e945";
$icon-mobile: "\e946";
$icon-opciones: "\e947";
$icon-tiempo: "\e948";
