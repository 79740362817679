@use "../utils" as u;

.signIn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__form-container {
    width: 100%;
  }

  &__title {
    width: 100%;
    height: 20%;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid u.$catskill-white;

    &--title {
      display: flex;
      margin-bottom: 32px;
    }

    &--title h2 {
      margin-right: 16px;
    }
  }

  &__form {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    position: relative;

    &--link {
      text-decoration: none;
    }

    &--btn {
      height: 42px;
      width: 100%;
      max-width: 500px;
      margin-top: 12px;
      margin-bottom: 32px;
      border-radius: 50px;
      border: 1px solid u.$dodger-blue;
      background-color: u.$dodger-blue;
      box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.24);
      color: u.$zircon;
      cursor: pointer;

      &--disable {
        height: 42px;
        width: 100%;
        max-width: 500px;
        margin-top: 12px;
        margin-bottom: 32px;
        border-radius: 50px;
        color: u.$white;
        border: 1px solid u.$mauve;
        background-color: u.$mauve;
        box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.24);
        cursor: context-menu;
      }
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -40px;

    &--text {
      display: inline-block;
      height: 40px;
      margin-left: 10px;
      line-height: 40px;
    }
  }

  &__submit .lds-ring-small {
    width: 30px;
    height: 30px;
  }

  &__submit .lds-ring-small .ringFragment {
    width: 30px;
    height: 30px;
    margin: 0;
  }

  &__separator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    color: u.$catskill-white;
    margin-bottom: 24px;
  }

  &__separator hr {
    width: 100%;
    border: 1px solid u.$catskill-white;
  }
}

//Google Button

.qJTHM,
.nsm7Bb-HzV7m-LgbsSe,
.nsm7Bb-HzV7m-LgbsSe.hJDwNd-SxQuSe.i5vt6e-Ia7Qfc.JGcpL-RbRzK {
  font-family: u.$font_medium !important;
  font-size: u.rem(16) !important;
  line-height: u.rem(18) !important;
}
