@use "../utils/index" as u;

.slideInDown--floating {
  position: absolute;
  top: u.rem(75);
  z-index: 5;
  overflow-y: hidden;
}

/*
  Transición en entrada
*/

.slideInDown-enter {
  opacity: 0;
  height: 0;
}

.slideInDown-enter-active {
  opacity: 1;
  height: u.rem(175);
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInDown-enter-done {
  height: u.rem(175);
  opacity: 1;
}

/*
  Transición en salida
*/

.slideInDown-exit {
  opacity: 1;
  height: u.rem(175);
}

.slideInDown-exit-active {
  opacity: 1;
  height: 0;
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInDown-exit-done {
  opacity: 0;
  height: 0;
}

/*
  SlideDown Filtros
*/

.showFilters-sm-enter {
  height: u.rem(42);
}

.showFilters-sm-enter-active {
  height: u.rem(12) x;
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.showFilters-sm-enter-done {
  height: u.rem(12) x;
}

.showFilters-sm-exit {
  height: u.rem(12) x;
}

.showFilters-sm-exit-active {
  height: u.rem(42);
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.showFilters-sm-exit-done {
  height: u.rem(42);
}

.showFilters-enter {
  height: u.rem(42);
}

.showFilters-enter-active {
  height: u.rem(90);
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.showFilters-enter-done {
  height: u.rem(90);
}

.showFilters-exit {
  height: u.rem(90);
}

.showFilters-exit-active {
  height: u.rem(42);
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.showFilters-exit-done {
  height: u.rem(42);
}

.appearFilters-enter {
  opacity: 0;
}

.appearFilters-enter-active {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.appearFilters-enter-end {
  opacity: 1;
}

.appearFilters-exit {
  opacity: 1;
}

.appearFilters-enter-active {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.appearFilters-enter-end {
  opacity: 0;
}

/*
  Slide Warnings
*/

.slideWarnings-enter {
  opacity: 0;
  height: 0;
}

.slideWarnings-enter-active {
  opacity: 1;
  height: u.rem(550);
  transition: all 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideWarnings-enter-done {
  height: u.rem(550);
  opacity: 1;
}

.slideWarnings-exit {
  height: u.rem(550);
  opacity: 1;
}

.slideWarnings-exit-active {
  opacity: 0;
  height: 0;
  transition: all 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideWarnings-exit-donde {
  opacity: 0;
  height: 0;
}

/*
  SlideIn Team
*/

.slideInTeamCompleto-enter {
  opacity: 0;
  height: 0;
}

.slideInTeamCompleto-enter-active {
  opacity: 1;
  height: u.rem(140);
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInTeamCompleto-enter-done {
  height: u.rem(140);
  opacity: 1;
}

.slideInTeamCompleto-exit {
  opacity: 1;
  height: u.rem(140);
}

.slideInTeamCompleto-exit-active {
  opacity: 1;
  height: 0;
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInTeamCompleto-exit-done {
  opacity: 0;
  height: 0;
}

.slideInTeam-enter {
  opacity: 0;
  height: 0;
}

.slideInTeam-enter-active {
  opacity: 1;
  height: u.rem(110);
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInTeam-enter-done {
  height: u.rem(110);
  opacity: 1;
}

.slideInTeam-exit {
  opacity: 1;
  height: u.rem(110);
}

.slideInTeam-exit-active {
  opacity: 1;
  height: 0;
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInTeam-exit-done {
  opacity: 0;
  height: 0;
}

/*
  SlideIn AddUser
*/

.slideInAddUser-enter {
  opacity: 0;
  height: 0;
}

.slideInAddUser-enter-active {
  opacity: 1;
  height: u.rem(115);
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInAddUser-enter-done {
  height: u.rem(115);
  opacity: 1;
}

.slideInAddUser-exit {
  opacity: 1;
  height: u.rem(115);
}

.slideInAddUser-exit-active {
  opacity: 1;
  height: 0;
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInAddUser-exit-done {
  opacity: 0;
  height: 0;
}

/*
  SlideIn Magic Link Options
*/

.slideInLinkOption-enter {
  opacity: 0;
  height: 0;
}

.slideInLinkOption-enter-active {
  opacity: 1;
  height: u.rem(172);
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInLinkOption-enter-done {
  height: u.rem(172);
  opacity: 1;
}

.slideInLinkOption-exit {
  height: u.rem(172);
  opacity: 1;
}

.slideInLinkOption-exit-active {
  opacity: 1;
  height: 0;
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInLinkOption-exit-done {
  opacity: 0;
  height: 0;
}

/*
  SlideIn SDK
*/

.slideInSDK-enter {
  opacity: 0;
  height: 0;
}

.slideInSDK-enter-active {
  opacity: 1;
  height: u.rem(135);
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInSDK-enter-done {
  height: u.rem(135);
  opacity: 1;
}

.slideInSDK-exit {
  height: u.rem(135);
  opacity: 1;
}

.slideInSDK-exit-active {
  opacity: 1;
  height: 0;
  transition: height 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slideInSDK-exit-done {
  opacity: 0;
  height: 0;
}
