@use "../utils" as u;

.billingTotalsCard {
  width: 100%;
  height: 100%;
  padding: 24px;

  &--title {
    display: flex;
    justify-content: space-between;
  }

  &--title > h2 {
    width: u.rem(150);
  }

  &__container {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: auto;
    border-top: 1px solid u.$catskill-white;

    &--triple {
      @extend .billingTotalsCard__container;
      grid-template-columns: repeat(3, 33.3%);
    }

    &__data {
      padding: 8px;

      &--border {
        @extend .billingTotalsCard__container__data;
        border-right: 1px solid u.$catskill-white;
      }

      &--spacing {
        @extend .billingTotalsCard__container__data;
        padding-left: 32px;
      }

      &--border-spacing {
        @extend .billingTotalsCard__container__data;
        border-right: 1px solid u.$catskill-white;
        padding-left: 32px;
      }
    }
  }
}

.calendar-inBilling {
  border: none;
  width: u.rem(200);
  flex-direction: row-reverse;

  &--calendar {
    top: 40px;
  }

  &--calendar-btn {
    top: 450px;
  }
}

.calendar-inBilling .icon-calendario::before {
  font-size: u.rem(20);
  margin: 0 !important;
  color: u.$dodger-blue;
}
