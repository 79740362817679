@use "../utils/index" as u;

.chart {
  background-color: u.$white;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "title title"
    "chart calendar";
  grid-template-columns: 75% 25%;
  grid-template-rows: 0.7fr 3fr;
  align-items: center;
  border-radius: 10px;
  padding: 0 16px;

  &--sdk {
    grid-template-areas:
      "chart calendar"
      "chart calendar";
  }

  &--error {
    grid-template-areas:
      "title title"
      "chart chart";
  }

  &--no-data {
    grid-template-areas:
      "chart chart"
      "chart chart";
  }

  &--title {
    grid-area: title;
    padding: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__text {
      display: flex;
      gap: 8px;
    }
  }

  &__graph {
    grid-area: chart;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__calendar {
    grid-area: calendar;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__container {
      width: fit-content;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &--btn {
        background-color: u.$white;
        border: 1px solid u.$shark;
        padding: 5px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 8px;
      }

      &--icon::before {
        margin: 0 !important;
        font-size: u.rem(28);
      }

      &--calendar {
        padding: 10px;
        height: 395px;
        background-color: u.$zircon;
        border: 1px solid u.$dodger-blue;
        border-radius: 10px;
        position: absolute;
        top: 0;
        right: -365px;
        z-index: 5;

        &-btn {
          width: 75px;
          padding: 8px;
          border-radius: 50px;
          background-color: u.$dodger-blue;
          color: u.$zircon;
          position: absolute;
          top: 405px;
          right: -365px;
          z-index: 5;
        }

        &__buttons {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__container {
            width: 70%;
          }
        }

        &__buttons h4 {
          padding-right: 8px;
        }
      }
    }

    &--data {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding: 0 8px;

      &__totals {
        &--element {
          margin-bottom: 20px;
        }
      }

      &__services {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__table {
          font-family: u.$font_regular !important;
          letter-spacing: 0;
          font-size: u.rem(14) !important;
          line-height: u.rem(18) !important;
        }

        &__table th,
        &__table td {
          font-weight: 400 !important;
          text-align: end;
          border-bottom: 1px solid u.$catskill-white;
          padding: 12px 0;
        }

        &--element {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &-color {
            width: 16px;
            height: 16px;
            border-radius: 16px;
            margin-right: 10px;
          }
        }
      }

      &__services > .button__relative-container {
        align-self: flex-end;
      }
    }
  }
}

/*
  Modificar valores de la librería calendario
*/

.rdrDateDisplayWrapper {
  display: none !important;
}

.rdrCalendarWrapper {
  border-radius: 16px;
}

.rdrMonthAndYearPickers {
  margin-bottom: 10px;
  text-transform: capitalize;
  order: -1;
  padding-left: 22px;
  justify-content: flex-start !important;
}

.rdrMonthAndYearWrapper {
  font-family: u.$font_bold;
  font-size: 1.2rem;
  padding-top: 0 !important;
}

.rdrNextPrevButton {
  border-radius: 0;
  background-color: inherit !important;
  width: 2.2rem !important;
  height: 2.2rem !important;
  margin: 0 !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.rdrNextPrevButton:hover {
  background: inherit !important;
}

.rdrPprevButton i {
  border-width: 0 !important;
  border-color: inherit;
  transform: none;
  background-image: url("../icons/images/icon-anterior.svg");
  background-size: cover;
  background-position: 10px 0;
  width: 100% !important;
  height: 100% !important;
}

.rdrNextButton i {
  border-width: 0 !important;
  border-style: none;
  margin: 0;
  transform: none;
  background-image: url("../icons/images/icon-siguiente.svg");
  background-size: cover;
  background-position: -10px 0;
  width: 100% !important;
  height: 100% !important;
}

/*
  Modificar los valores de la librería chart
*/
.recharts-default-legend {
  display: none !important;
}

.recharts-default-tooltip {
  border-radius: 8px !important;
  background-color: u.$gulf-blue !important;
  color: u.$zircon !important;
}

[class^="recharts-tooltip-item"] {
  color: u.$zircon !important;
  font-weight: bold;
}
