@use "../utils" as u;

.perfilData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  position: relative;

  &__container {
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
  }

  &__separator {
    border: 1px solid u.$catskill-white;
    width: calc(100% + 32px);
    position: absolute;
    bottom: 0;
    left: -16px;
  }
}
