@use "../utils" as u;

.layout {
  width: 99.9vw;
  display: grid;
  height: 100vh;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "sidebar main"
    "sidebar main";
  overflow: hidden;

  &__main,
  &__main--close {
    grid-area: main;
    padding-right: 10px;
    margin-bottom: 16px;

    &__header {
      width: 100%;
      padding-right: 10px;
      padding-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &__title {
        color: u.$shark;
      }

      &__icons {
        display: flex;
        align-items: center;

        &--btn,
        &--user,
        &--contact {
          margin-left: 24px;
        }

        &--btn {
          background-color: inherit;
          border: none;
          cursor: pointer;
          width: u.rem(24);
          font-size: u.rem(24);
          height: u.rem(24);
          display: block;
          position: relative;
        }

        &--notificar {
          width: u.rem(17.5);
          height: u.rem(17.5);
          border-radius: 100%;
          background-color: u.$shuttle-gray;
          color: u.$white;
          padding: 0 4.5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: u.rem(-7.8);
          top: u.rem(-3.3);
        }

        &--contact {
          color: u.$shark;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &--user,
        &--contact > img {
          height: u.rem(24);
          font-size: u.rem(24);
          cursor: pointer;
        }

        &--user > span::before,
        &--contact > div::before {
          margin: 0 !important;
        }
      }
    }

    &__section {
      background-color: u.$zircon;
      height: 100vh;
      padding: 16px 40px;
      padding-right: 30px;
      padding-bottom: 0;
      overflow: auto;
      &--close {
        width: 82vw;
        padding: 16px 40px;
        padding-right: 30px;
        padding-bottom: 0;
      }
    }

    &__component {
      height: 100%;
    }
  }

  &__sidebar,
  &__sidebar--close {
    background-color: u.$white;
    padding-top: 16px;
    width: u.rem(200);
    grid-area: sidebar;

    &__nav {
      height: 100vh;

      &__title {
        display: flex;
        align-items: center;
        justify-content: center;

        &--logo {
          display: block;
          width: 100%;
          margin: auto;

          &-max {
            width: 50%;
            max-width: 55px;
            margin-bottom: 16px;
          }
        }

        &--logo--trueFace {
          display: block;
          width: 70%;
          margin: auto;

          &-max {
            width: 70%;
            max-width: 55px;
          }
        }
      }

      &__btn-toggle {
        width: 100%;
        padding-right: 8px;
        height: u.rem(32);
        text-align: right;
      }

      &__btn-toggle > button::before {
        margin: 0 !important;
        font-size: u.rem(32);
        color: u.$dodger-blue;
      }

      &__links-section {
        height: 75%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 10px;
      }

      &--section {
        width: 100%;

        &-1,
        &-2 {
          margin-top: 32px;
          min-height: u.rem(232);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px 0;
        }

        &-2 {
          min-height: u.rem(88);
          justify-content: space-around;
        }
      }

      &--link {
        display: block;
        width: 100%;
        padding: 8px 8px 8px 16px;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: u.$shark;
        text-decoration: none;

        &--disable {
          color: u.$shuttle-gray;
          cursor: context-menu;
        }

        &--disable span > span:hover {
          text-decoration: none !important;
        }
      }

      &--active-link {
        @extend .layout__sidebar__nav--link;
        color: u.$dodger-blue;
        background-color: u.$catskill-white;
        text-decoration: none;
      }

      &--link-title {
        @extend .layout__sidebar__nav--link;
        padding: 0;
      }

      &--link > span::before {
        font-size: u.rem(24);
      }

      &--link > span {
        display: flex;
        align-items: center;
      }

      &--link span > span:hover,
      &--active-link span > span:hover,
      &--link span > span:active,
      &--active-link span > span:active {
        text-decoration: underline;
      }

      &--link span > span,
      &--active-link span > span {
        font-family: u.$font_regular;
      }
    }

    &__nav h4 {
      font-family: u.$font_regular;
      font-size: u.rem(12);
      line-height: u.rem(24);
      color: u.$shuttle-gray;
    }
  }

  &__sidebar--close {
    width: u.rem(80);

    &__nav {
      &__btn-toggle {
        padding-left: 16px;
      }
    }
  }
}

.header--align-end {
  justify-content: flex-end;
}

@media (max-width: u.$mobile) {
  .layout {
    &__main {
      padding-left: u.rem(12);
      padding-right: u.rem(3);

      &__header--close {
        width: 100%;
      }
    }

    &__main--close {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      overflow-y: auto;
    }
  }

  .layout__sidebar--close__nav--section {
    overflow: visible;
  }
}

/*
  NavBar Transitions
*/

.openNav-enter {
  width: 5rem;
}

.openNav-enter-active {
  width: 12.5rem;
  transition: width ease-in-out 0.3s;
}

.openNav-enter-done {
  width: 12.5rem;
}

.openNav-exit {
  width: 12.5rem;
}

.openNav-exit-active {
  width: 5rem;
  transition: width ease-in-out 0.3s;
}

.openNav-exit-done {
  width: 5rem;
}

.linkImage,
.linkImageActive {
  display: flex;
  gap: 8px;
  align-items: center;
}

.linkImage > img {
  filter: brightness(0);
  width: 10%;
  min-width: u.rem(24);
}
