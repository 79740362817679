@use "../utils/index" as u;

.selectSDK {
  position: relative;
  &__btn {
    min-width: u.rem(132);
    padding: 8px 12px;
    flex-direction: row-reverse;
    justify-content: space-between;
    color: u.$shark;
    background-color: u.$white;
    border: 1px solid u.$shark;
    box-shadow: none;
  }

  &__btn span {
    font-family: u.$font_regular !important;
  }

  &__dropdown {
    width: u.rem(230);

    &__content {
      overflow-y: hidden;
    }

    &__element {
      padding: 8px 16px;
      justify-content: space-between;
    }

    &__element-selected {
      border: 1px solid u.$dodger-blue;
      flex-direction: row-reverse;
      border-radius: 4px;
    }

    &__icon {
      width: u.rem(24);
      height: u.rem(24);
      font-size: u.rem(24);
    }

    &__icon::before {
      margin: 0;
      font-size: u.rem(24);
    }
  }
}
