@use "../utils/index" as u;

html {
  box-sizing: border-box;
  font-size: 100%;
  font-family: u.$font_regular;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

* {
  font-family: u.$font_regular;
  scrollbar-color: u.$catskill-white u.$white;
  scrollbar-width: thin;
}

body {
  font-family: u.$font_regular;
}

button {
  background-color: inherit;
  border: none;
  cursor: pointer;
}
