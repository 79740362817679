@use "../utils" as u;

.snackBar {
  width: u.rem(416);
  height: 4.5rem;
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  border: 1px solid u.$keppel;
  position: absolute;
  bottom: -50px;
  z-index: 15;

  &--notification-snack {
    @extend .snackBar;
    width: u.rem(480);
    height: u.rem(98);
    height: auto;
    border: 1px solid u.$copperfield;
    padding: 16px;
    gap: 8px;
  }

  &--notification {
    @extend .snackBar--notification-snack;
    position: static;
    box-shadow: none;

    &--seen {
      @extend .snackBar--notification;
      border: none;
      border-bottom: 1px solid u.$catskill-white;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__title {
      display: flex;
      gap: 4px;
      align-items: flex-start;
    }

    &__mark {
      margin: 4px;
      border-radius: u.rem(8);
      width: u.rem(8);
      height: u.rem(8);
      background-color: u.$copperfield;
    }

    &__text {
      padding-left: 20px;
    }

    &__btn {
      height: u.rem(24);
      font-size: u.rem(24);
    }

    &__btn > div::before {
      margin: 0;
    }
  }

  &--error {
    border: 1px solid u.$copperfield;
  }

  &__icon {
    padding: 1.44rem 0.75rem;
    background-color: u.$iceberg;
    color: u.$keppel;
    font-size: 1.5rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn > span::before {
    margin-right: 0 !important;
  }

  &__icon--error {
    background-color: u.$peach-schnapps;
    color: u.$copperfield;
  }

  &__text {
    padding: 1rem;
  }

  &__title {
    font-family: u.$font_bold;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  &__btn {
    padding: 1rem;
    font-size: 1.5rem;
  }
}

/*
    Transiciones
*/

.contenedorSnackSlide {
  width: u.rem(416);
  position: absolute;
  z-index: 9999;
  bottom: 0;

  &--notifications {
    @extend .contenedorSnackSlide;
    width: u.rem(480);
    top: 36px;
    right: 0;
  }
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease;
}

.slide-exit {
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(100%);
  transition: transform 500ms ease;
}
