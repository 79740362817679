.reportarFlagged {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &--title {
    margin-top: 16px;
    padding-left: 12px;
  }

  &--comment {
    padding: 8px 24px;
  }

  &--button {
    margin-left: 16px;
    margin-right: 16px;
    width: fit-content;
  }

  &__btn-container {
    display: flex;
    align-items: center;
  }

  &__author {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
  }
}
