@use "./colors" as u;

.border {
  border-style: solid;
  border-width: thin;
}

.border-dark {
  border-color: u.$shark;
}

.border-light {
  border-color: u.$catskill-white;
}

.border-zircon {
  border-color: u.$zircon;
}

.border-blue {
  border-color: u.$dodger-blue !important;
}

.rounded-1 {
  border-radius: 4px;
}

.rounded-2 {
  border-radius: 10px;
}

.rounded-3 {
  border-radius: 50px;
}

.border-table-start {
  border: 1px solid u.$dodger-blue;
  border-right: none;
}

.border-table-middle {
  border: 1px solid u.$dodger-blue;
  border-right: none;
  border-left: none;
}

.border-table-end {
  border: 1px solid u.$dodger-blue;
  border-left: none;
}
