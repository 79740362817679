@use "../utils" as u;

.recovery {
  width: 50vw;
  height: 100vh;
  margin: auto;
  padding-top: 15vh;
  overflow: hidden;

  &__title {
    margin: auto;
    text-align: center;
    border-bottom: 1px solid u.$catskill-white;
    padding-bottom: 20px;
  }

  &__title h2 {
    margin-bottom: 10px;
  }

  &__form {
    width: 100%;
    min-height: 25vh;
    margin: 30px auto;
  }

  &--link,
  &--link1 {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 16px;
  }

  &--link {
    color: u.$shark;
    font-family: u.$font_regular;
    text-decoration: none;
  }
}
