@use "../utils/index" as u;

.reporteDataAccordion {
  &__title {
    padding: 20px 0 !important;

    &--blue {
      @extend .reporteDataAccordion__title;
      padding: 0 !important;
    }

    &__container {
      display: inline-flex;
      gap: 8px;
    }

    &__text {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 8px;

      &--blue {
        @extend .reporteDataAccordion__title__text;
        gap: 4px;
        padding: 0 4px;
        margin-bottom: 16px;
      }
    }

    &__icon::before {
      margin: 0;
      font-size: u.rem(24);
    }
  }

  &__warnings {
    width: 100%;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
      border-radius: 8px;
      border: 1px solid u.$copperfield;
      background-color: u.$peach-schnapps;
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &--overflow {
      width: 100%;
      max-height: u.rem(60);
      overflow-y: scroll;
    }

    &--overflow p {
      padding-right: 8px;
    }

    &__btn {
      width: u.rem(18);
      height: u.rem(18);

      &--up {
        @extend .reporteDataAccordion__warnings__btn;
        position: absolute;
        z-index: 5;
        top: -15px;
        right: -9px;
      }

      &--down {
        @extend .reporteDataAccordion__warnings__btn;
        position: absolute;
        z-index: 5;
        bottom: -6px;
        right: -9px;
      }
    }

    &__btn::before {
      margin: 0;
      font-size: u.rem(18);
    }

    &__scroll-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
  }

  &__description {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    padding: 12px 16px;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &__description > h4 {
    margin-bottom: 0;
    width: 40%;
  }

  &__sub-description {
    padding-bottom: 16px;
  }

  &__sub-description > div {
    padding: 0;
    padding-bottom: 12px;
  }

  &__sub-description > h3 {
    padding-bottom: 12px;
  }

  &__sub-studies {
    border-bottom: 1px solid u.$catskill-white;
    padding-bottom: 16px;
    margin-bottom: 8px;
  }

  &__sub-email {
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__container {
      display: flex;
      flex-direction: column;
      gap: 0;
      width: 100%;
      border: 1px solid u.$catskill-white;
      border-radius: 8px;
    }

    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 8px;
      border-bottom: 1px solid u.$catskill-white;
    }

    &__title > img {
      width: u.rem(24);
    }

    &__info {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__picture {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__picture > img {
      width: u.rem(64);
    }

    &__icons {
      padding: 16px 0;
    }

    &__icons > h5 {
      padding: 8px 0;
    }

    &__icons-container {
      display: flex;
      align-items: center;
      gap: 34px;
      padding: 12px 0;
    }

    &__icons-container > img {
      width: u.rem(30);
    }
  }

  &__sub-document {
    width: 70%;
  }

  &__sub-document > &__description > span {
    display: inline-block;
    width: 40%;
    text-align: left;
  }

  &__image {
    padding: 16px;
    display: flex;
    align-items: flex-start;
    gap: 24px;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      width: 50%;
      max-width: 410px;

      &--modal {
        @extend .reporteDataAccordion__image__container;
        width: 100%;
        max-width: none;
        max-height: 75vh;
        overflow: hidden;
      }
    }

    &__container > img {
      width: 90%;
    }
  }

  &__modal {
    height: 100vh;
    width: 100vw;
    background-color: u.$shuttle-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99999999999999999999;
    top: 0;
    right: 0;

    &__container {
      width: 90%;
      max-width: 1000px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      border-radius: 10px;
      background-color: u.$white;
    }

    &__container > h5 {
      align-self: flex-start;
    }
  }

  &__sub-pep {
    &__container {
      padding-left: 32px;

      &--sub {
        padding: 0;
        margin: 8px 0;
      }
    }

    &__separator {
      border-bottom: 1px solid u.$catskill-white;
      margin-bottom: 16px;
    }

    &__position {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 400px;

      &__title {
        padding-top: 8px;
      }
    }

    &__date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
    }

    &__link {
      display: block;
      padding: 8px 0;
    }

    &__dataItem {
      width: clamp(200px, 100%, 400px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
    }
  }
}

.reporteDataAccordion--double-border {
  @extend .reporteDataAccordion;
  border-top: 1px solid u.$catskill-white;
}

.MuiPaper-root.reporteDataAccordion,
.MuiPaper-root.reporteDataAccordion--double-border {
  box-shadow: none;
}

.MuiPaper-root.MuiAccordion-root.reporteDataAccordion::before,
.MuiPaper-root.MuiAccordion-root.reporteDataAccordion--double-border::before {
  background-color: rgba(0, 0, 0, 0) !important;
}

#studies-sub-header,
#listed-weeks-sub-header,
#email-sub-header,
#document-sub-header {
  min-height: auto !important;
}
