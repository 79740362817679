@use "../utils/index" as u;

.sdkPercentages {
  padding: 16px;
  padding-right: 8px;

  &__row {
    display: flex;
    gap: 56px;
    border-bottom: 1px solid u.$catskill-white;
    padding: 12px 48px;
    padding-left: 0;
  }

  &__text-end {
    display: block;
    width: 80%;
    text-align: end;
    white-space: nowrap;
  }

  &__warning {
    width: 85%;
    min-width: 300px;
    padding-top: 16px;
  }
}
