@use "../utils/index" as u;

.message-component {
  width: 50%;
  min-height: 10rem;
  padding: 1rem;
  margin: 1rem auto;
  text-align: center;
  //background-color: white !important;
  color: u.$com;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message-component button {
  color: inherit;
  margin-top: 10px;
}
