@use "../utils" as u;

.layout__main__section__consultas__table {
  width: 100%;
  max-height: 100vh;
  margin-top: 1rem;
  display: inline-table;
  border-collapse: separate;
  table-layout: fixed;
  background-color: u.$white;
  border-spacing: 0;

  &__thead {
    &__th {
      height: 2rem;
      padding: 4px 8px;
      margin-bottom: 8px;
      border-top: 1px solid u.$catskill-white;
      border-bottom: 2px solid u.$catskill-white;
      text-align: left;
      background-color: u.$white;
      position: sticky;
      top: 0;
      z-index: 1;
      white-space: nowrap;
    }

    &__th span {
      margin-right: 8px;
    }

    &__th .space {
      display: inline-block;
    }

    //Tabla de Consultas con reporte
    &--tr th.withReport:nth-of-type(1) {
      width: 15%;
    }

    &--tr th.withReport:nth-of-type(2) {
      width: 16%;
    }

    &--tr th.withReport:nth-of-type(3) {
      width: 25%;
    }

    &--tr th.withReport:nth-of-type(4) {
      width: 13%;
    }

    &--tr th.withReport:nth-of-type(5) {
      width: 19%;
    }

    //Tabla de Usuarios

    &--tr th.team:nth-of-type(1) {
      width: 15%;
    }

    &--tr th.team:nth-of-type(3),
    &--tr th.team:nth-of-type(4) {
      width: 15%;
    }

    &--tr th.team:nth-of-type(2),
    &--tr th.team:nth-of-type(5) {
      width: 20%;
    }

    //Tabla de Facturas

    &--tr th.billing:nth-of-type(1) {
      width: 25%;
    }

    &--tr th.billing:nth-of-type(2) {
      width: 15%;
    }

    &--tr th.billing:nth-of-type(3) {
      width: 18%;
    }

    &--tr th.billing:nth-of-type(4) {
      width: 12%;
    }

    &--tr th.billing:nth-of-type(5) {
      width: 20%;
    }
  }

  &__tbody {
    padding: 10px;
    &__td {
      white-space: nowrap;
      padding: 4px 8px;
      position: relative;

      &__icon::before {
        margin: 0;
        font-size: u.rem(24);
      }
    }

    &__td .error,
    &__td .ok,
    &__td .warning {
      display: inline-block;
      width: 4rem;
      padding: 0.2rem 0.4rem;
      text-align: center;
    }

    &__td .error {
      background-color: u.$peach-schnapps;
      color: u.$copperfield;
    }

    &__td .ok {
      color: u.$keppel;
      background-color: u.$iceberg;
    }

    &__td .warning {
      color: u.$com;
      background-color: u.$buttermilk;
    }

    &__td .btn {
      font-size: 1.5rem;
      margin-left: 5px;
    }
  }
}

.table-inDash {
  position: static;
  border-collapse: collapse;
}

.searchStyles {
  height: auto;
}

.table__name {
  display: flex;
  align-items: center;
  gap: 8px;
}
