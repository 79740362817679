@use "../utils/" as u;

.SearchBarWithFilters {
  &__li-title {
    background-color: u.$catskill-white;
    border-radius: 4px;
    padding: 2px 4px;
  }

  &__icon {
    width: u.rem(16);
    height: u.rem(16);
  }

  &__icon:before {
    font-size: u.rem(16);
  }
}
