@use "../utils/index" as u;

.layout__main__section__consultas {
  background-color: u.$white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &__header {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &--title {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &--title .icon-anterior {
      font-size: u.rem(24);
    }

    &--title .icon-anterior::before {
      margin: 0;
    }

    &--search-modifiers {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      max-width: 600px;
    }
  }

  &__table-container {
    width: 100%;
    height: 80vh; //- Problemas scroll Firefox
    overflow-y: scroll;
    scrollbar-width: auto;
  }
}

.layout__main__section__consultas--loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  margin-left: 8px;
  width: 82%;
  height: u.rem(24);
  background-color: u.$white;
  position: sticky;
  bottom: 0;

  &-text {
    display: block;
    height: 100%;
    line-height: 70%;
    font-size: u.rem(17.6);
    background-color: u.$white;
    position: absolute;
    z-index: 5;
  }
}

.consultas {
  &__reporte {
    width: 70%;
    height: 91vh;
    position: relative;
    background-color: u.$white;
    overflow-y: auto;

    &__container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 24px;
    }

    &__details-container {
      padding: 24px;
      padding-right: 14px;
      background-color: u.$white;
      width: 37%;
    }

    &__searchDetails {
      height: 80vh;
      overflow-y: scroll;
      padding-right: 10px;
      position: relative;
    }
  }
}

.consultas-inDash {
  min-height: 450px;
  max-height: 45vh;
  padding: 0.5rem;
  overflow: hidden;
  border-radius: 10px;
}

.consultas-inDash .layout__main__section__consultas__header {
  margin-bottom: u.rem(8);
  padding: u.rem(16);
}

.consultas-inDash .layout__main__section__consultas__table-container {
  width: 100%;
  height: 45vh;
  overflow: hidden;
}

.loaderStyles {
  // height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: u.$mobile) {
  .layout__main__section__consultas {
    &__header {
      &--search-modifiers {
        width: 100%;
        margin-top: 8px;
      }
    }
  }
}
