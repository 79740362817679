.noDataToShow {
  width: 50%;
  max-width: 450px;
  height: fit-content;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  &__img {
    width: 50%;
    max-width: 150px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__container > p,
  &__container > h4 {
    text-align: center;
  }
}
