@use "../utils" as u;

.changeStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__iconName {
    width: u.rem(28);
    height: u.rem(28);
    border-radius: u.rem(28);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  &__colorShark {
    margin-left: 8px;
    color: u.$shark;
  }

  &__colorShark::before {
    margin-right: 0 !important;
  }
}

.changeStatus .button-icon::before {
  display: inline-block;
  width: u.rem(20);
  height: u.rem(20);
  font-size: u.rem(20);
}
