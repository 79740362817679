@use "../utils" as u;

.busqueda,
.busqueda--close {
  background-color: u.$zircon;
  width: 100%;
  display: flex;

  &__userID {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 4px 16px;

    &__label {
      width: 55%;
    }

    &__input {
      width: 45%;
      padding: 8px 14px;
      border: 1px solid rgba(0, 0, 0, 0.55);
      border-radius: 14px;
      outline: none !important;
    }

    &__input:focus {
      border-color: u.$dodger-blue;
    }
  }

  &__form {
    min-height: 80vh;
    background-color: u.$white;
    padding: 24px;
    margin-right: 24px;
    width: 70%;
    max-width: 800px;

    &__container {
      &__header {
        &--title {
          display: flex;
          justify-content: space-between;
        }

        &--title > section {
          width: fit-content;
          display: flex;
          align-items: center;
        }

        &--title > section > h2 {
          margin-right: 7px;
        }

        &--title > section > span {
          margin-top: 5px;
        }

        &--tabs {
          margin-top: 16px;
          margin-bottom: 24px;
          height: auto !important;
        }
      }

      &--ID-form {
        display: flex;
        gap: 8px;
        max-width: 475px;
        position: relative;
        margin-top: 32px;
        flex-direction: column;

        &-loader {
          width: 60px;
          height: 60px;
          position: absolute;
          right: -65px;
          bottom: 16px;
        }
      }

      &--ID-form .textInputComponent {
        max-width: 280px !important;
      }

      &--complete-form {
        width: 100%;
        max-width: 750px;
        margin-top: 24px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, auto);
        gap: 0 24px;
        justify-items: center;
      }
    }
  }

  &__form__group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__form__container--ID-form .btnMostrarResultado {
    margin-bottom: 16px;
  }

  &__btnContainer {
    width: 100%;
    max-width: 750px;
    height: u.rem(56);
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  &__btnContainer button {
    margin-bottom: 0;
  }

  &__btnContainer .errorSlide {
    width: 50%;
    padding-left: 0;
    padding-right: 32px;
    margin-top: 12px !important;
  }

  &__reporte {
    width: 70%;
    min-height: 85vh;
    position: relative;
    background-color: u.$white;

    &__container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 24px;
    }

    &__details-container {
      padding: 24px;
      background-color: u.$white;
      width: 33%;
    }
  }
}

.loader-completo {
  bottom: 0;
}

.busqueda--close {
  &__form {
    width: 100%;
  }
}
