@use "../utils" as u;

.breadcrumbs {
  min-height: 32px;
  margin-bottom: 5px;
  display: flex;
  padding-left: 3rem;

  &__flex {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &--link {
    color: u.$shark;
    text-decoration: none;
  }

  &--separator {
    display: inline-block;
    height: inherit;
  }

  &--separator::before {
    margin-right: 0;
  }
}
