@use "../utils" as u;

.landing__link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: u.rem(40);
  margin: u.rem(48) 0;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 18px;
    h1 {
      font-size: u.rem(32);
      font-weight: 700;
    }

    h2 {
      font-size: u.rem(16);
      font-weight: 400;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    font-size: u.rem(14);
    gap: u.rem(24);

    &__card {
      display: flex;
      width: 43%;
      flex-direction: column;
      gap: u.rem(24);
      line-height: u.rem(18);

      &__header {
        display: flex;
        flex-direction: column;
        gap: u.rem(10);

        &__code {
          font-size: u.rem(12);
          width: fit-content;
          display: flex;
          justify-items: center;
          gap: u.rem(7);
          border-radius: u.rem(4);
          border: 1px solid u.$dodger-blue;
          color: u.$dodger-blue;
          padding: u.rem(2) u.rem(10);
        }
      }

      &__img {
        width: 100%;
        height: 100%;
        max-height: u.rem(381);
        object-fit: contain;
        border-radius: 8px;
      }
    }
  }
}
