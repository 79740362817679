@use "../utils" as u;

.MuiRadio-radio.Joy-checked,
.MuiRadio-colorNeutral .JoyRadio-radio,
.MuiRadio-colorNeutral .MuiRadio-radio {
  box-sizing: border-box;
  width: u.rem(18);
  height: u.rem(18);
}

.MuiRadio-icon,
.JoyRadio-icon {
  width: u.rem(8) !important;
  height: u.rem(8) !important;
}

/*
    dodger-blue variant
*/

.blue.MuiRadio-root .JoyRadio-radio,
.blue.MuiRadio-root .MuiRadio-radio {
  background-color: u.$white !important;
  border: 1px solid u.$shuttle-gray !important;
}

.blue .MuiRadio-radio.Joy-checked,
.blue .MuiRadio-radio.Mui-checked {
  color: u.$white !important;
  background-color: u.$dodger-blue !important;
}

.blue.MuiRadio-root .JoyRadio-radio:hover,
.blue.MuiRadio-root .MuiRadio-radio:hover {
  background-color: u.$catskill-white !important;
}

/*
    light-gray variant
*/
.gray.MuiRadio-root .JoyRadio-radio,
.gray.MuiRadio-root .MuiRadio-radio {
  background-color: u.$white !important;
  border: 1px solid u.$light-gray !important;
}

.gray .MuiRadio-radio.Joy-checked,
.gray .MuiRadio-radio.Mui-checked {
  color: u.$white !important;
  background-color: u.$light-gray !important;
}

.gray.MuiRadio-root .JoyRadio-radio:hover,
.gray.MuiRadio-root .MuiRadio-radio:hover {
  background-color: u.$catskill-white !important;
}
