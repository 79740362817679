@use "../utils" as u;

.truncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  &__span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.truncateText:hover {
  cursor: context-menu;
  width: fit-content;
  overflow: visible;
  white-space: nowrap;
  background-color: u.$zircon;
  position: absolute;
  z-index: 1000;
  top: u.rem(2.5);
  border: 1px solid u.$catskill-white;
  padding: 4px 8px;
}
