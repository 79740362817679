@use "../utils/" as u;

.cargaBatch {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  &__download {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;
    border: 1px solid u.$danube;
    border-radius: 8px;
    background-color: u.$zircon;

    &__text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }

  &__stepper-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__stepper {
    &__content {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__description {
      padding-left: 20px;
    }

    &__btn-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      gap: 24px;
    }

    &__btn {
      flex-direction: row-reverse;
    }

    &__btn-container [class^="icon-"]::before,
    &__btn-container [class*=" icon-"]::before {
      margin: 0;
      font-size: u.rem(20);
    }
  }

  &__drop-zone {
    width: 100%;
    min-height: 235px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &__img {
      width: u.rem(32);
    }
  }
}
