.sendPassword {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--element {
    margin-bottom: 32px;
  }

  &__change .custom-input .MuiInputBase-root fieldset,
  &__change .custom-input,
  &__change .custom-input .MuiInputBase-root,
  &__change .custom-label,
  &__change .textInputComponent {
    width: 375px !important;
  }
}
