.rulesAccordion {
  &__title-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
