@use "../utils" as u;

.ajustes {
  background-color: u.$zircon;

  &__title {
    margin-bottom: 32px;
  }

  &__title > h2 {
    margin-bottom: 12px;
  }

  &__content {
    width: 50%;
    height: 100%;

    &__api {
      border: 1px solid u.$catskill-white;
      border-radius: 10px;
      padding: 16px 24px;
      margin-bottom: 24px;

      &--h3 {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid u.$catskill-white;
      }

      &--container {
        //width: 35px;
        height: 55px;
        padding-left: 16px;
        margin: 16px 0;
        display: flex;
        align-items: center;
        border-radius: 10px;
        background-color: u.$gulf-blue;
        color: u.$zircon;
      }

      &--buttons {
        //width: 350px;
        display: flex;
        justify-content: space-between;
      }

      &--buttons > button {
        color: u.$dodger-blue !important;
      }
    }

    &__busqueda {
      &--filtros {
        height: 100px;
        padding: 0 16px;
        margin-bottom: 16px;
        border-radius: 10px;

        &--p {
          margin-bottom: 20px;
        }
      }
    }
  }
}
