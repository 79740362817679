@use "../utils" as u;

.reporte__container {
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: u.$white;
  border-radius: 10px;
  padding: 16px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: u.$white;
    align-items: center;
    margin-bottom: 24px;

    &__container {
      position: relative;
      margin-bottom: 24px;

      &__separator {
        border: 1px solid u.$catskill-white;
        width: calc(100% + 32px);
        position: absolute;
        bottom: 0;
        left: -16px;
      }
    }

    &--title {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &--title h1 {
      display: inline-block;
    }

    &--title button {
      background-color: inherit;
      border: none;
      width: u.rem(24);
      height: u.rem(24);
      font-size: u.rem(24);
      display: flex;
      align-items: flex-start;
    }

    &--title button > span::before {
      margin: 0;
      font-size: u.rem(24);
    }

    &--btn {
      border: none;
      min-height: u.rem(20);
      padding: 8px 12px;
      display: inline-flex;
      align-items: center;
      border-radius: 28px;
      background-color: u.$dodger-blue;
      color: u.$white;

      &__text {
        font-family: u.$font_bold;
        font-weight: 700;
        font-size: u.rem(14);
        line-height: u.rem(20);
      }
    }

    &--btn .icon-flag::before {
      font-weight: 400;
      margin-right: 4px;
      font-size: u.rem(20);
    }
  }

  &__tabs {
    width: 100%;
    padding-top: 8px;
    position: relative;
    z-index: 2;
  }

  &__tabs button {
    margin: 0;
    margin-right: 16px;
  }

  &__resumen {
    @media (max-width: 1500px) {
      &__warnings {
        margin-top: 24px !important;
      }
    }

    &__warnings {
      padding: 16px;
      margin: 8px auto;
      height: 50px;
      width: 100%;
      border: 1px solid u.$copperfield;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &--text-container {
        height: 100%;
        display: flex;
        align-items: center;
      }

      &--icon {
        width: u.rem(26);
        height: u.rem(26);
        align-self: center;
        margin-right: 8px;
      }

      &--icon::before {
        font-size: u.rem(26);
        color: u.$copperfield;
      }

      &--btn {
        min-width: 90px;
      }

      &__modal {
        background-color: u.$zircon;
        width: 100%;
        min-height: 90px;
        max-height: 250px;
        border: 1px solid u.$copperfield;
        border-radius: 16px;
        position: absolute;
        top: 60px;
        left: 0;
        z-index: 5;
        overflow-y: scroll;

        &__container {
          position: relative;

          &--title {
            position: sticky;
            top: 0px;
            padding: 12px;
            display: flex;
            justify-content: space-between;
            background-color: u.$peach-schnapps;
          }
  
          &--title > button {
            font-size: u.rem(24);
          }
          &--texts {
            padding: 12px;
          }
  
          &--text {
            margin-bottom: 6px;
          }
        }


      }
    }

    &__network-reports {
      margin-top: 16px;
    }

    &__data-tab {
      margin-top: 16px;
      height: 55%;
    }

    &__data-tab h4 {
      margin-bottom: 16px;
    }
  }

  .selfCenter {
    margin: auto;
    margin-bottom: 10px;
  }

  &__error {
    margin-top: 1px !important;
    border-top: 1px solid u.$catskill-white;
    border-bottom: 1px solid u.$catskill-white;
    align-items: center;
    padding: 16px !important;

    &--btn {
      display: inline-block;
      width: u.rem(64);
      padding: 3.2px 6.4px;
      text-align: center;
      background-color: u.$peach-schnapps;
      color: u.$copperfield;
      margin-right: 12px;

      &--warning {
        @extend .reporte__container__error--btn;
        background-color: u.$buttermilk;
        color: u.$com;
      }
    }
  }
}

.inConsultas {
  height: 100%;
}

.inBusqueda {
  height: 90%;
}

/*
  Transición en entrada
*/
.warnings-enter {
  opacity: 0;
}

.warnings-enter-active {
  opacity: 1;
  transition: opacity ease-in 300ms;
}

.warnings-enter-done {
  opacity: 1;
}

/*
  Transición en salida
*/

.warnings-exit {
  opacity: 1;
}

.warnings-exit-active {
  opacity: 0;
  transition: opacity ease-in 300ms;
}

.warnings-exit-done {
  opacity: 0;
}

.h-90 {
  height: 90%;
}
