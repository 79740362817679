.reviews {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__searchContainer {
    width: 80%;
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
