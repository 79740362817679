@use "../utils" as u;

.layout__main__section__dashboard {
  width: 100%;
  height: 100%;

  &__buttons {
    border-bottom: 1px solid u.$catskill-white;
    height: 1.5rem;
    width: fit-content;
    grid-area: buttons;
    margin-bottom: 16px;

    &--btn {
      background-color: inherit;
      border: none;
      line-height: u.rem(24);
      cursor: pointer;

      &--space {
        margin-right: u.$space-3;
      }

      &--space-invert {
        margin-left: u.$space-3;
      }

      &--active {
        border-bottom: 2px solid u.$dodger-blue;
      }
    }
  }

  &__component {
    &--container {
      width: inherit;
      height: 35%;
      display: flex;
      justify-content: space-between;
    }

    &--container-billing {
      @extend .layout__main__section__dashboard__component--container;
      height: 30vh;
    }

    &--resumen {
      width: 100%;
      min-height: 375px;
    }

    &--data {
      width: 32%;
      min-width: 350px;
      min-height: 290px;
      height: fit-content;

      &__element {
        background-color: u.$white;
        margin: 0 !important;

        &--title {
          width: 100%;
          padding: 16px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &--border {
            min-height: 32px !important;
            border-bottom: 1px solid u.$catskill-white !important;
          }
        }

        &--title--btn {
          font-size: u.rem(14);
          width: 45%;
          max-width: 150px;
          padding: 4px 8px;
          border-radius: 50px;
          text-align: center;
          color: u.$shark;
          text-decoration: none;
          border: 1px solid u.$shark;
        }

        &--info {
          padding: 12px;
          border-bottom: 1px solid u.$catskill-white;
          border-top: 1px solid u.$catskill-white;

          &--inBilling {
            @extend .layout__main__section__dashboard__component--data__element--info;
            height: u.rem(135);
          }
        }

        &--info--text {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.dashboard {
  &__resumen-container {
    border-radius: 8px;
    border: 1px solid u.$catskill-white;
    background-color: u.$white;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__panel-section {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &--border {
      @extend .dashboard__panel-section;

      align-items: center;
      justify-content: center;
      border: 1px solid u.$catskill-white;
      border-radius: 8px;
    }
  }
}

@media (max-width: u.$mobile) {
  .layout__main__section__dashboard {
    &__component {
      &--container {
        width: 100%;
        height: 80%;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &--resumen {
        height: 48%;
        width: 100%;
      }

      &--data {
        width: 100%;
        height: 48%;
      }

      &--historial {
        height: 43%;
        margin-top: 16px;
      }
    }
  }
}

/*
  Overriding Accordion Styles
*/

.MuiAccordionSummary-content,
.Mui-expanded,
.MuiAccordionSummary-contentGutters {
  margin: 0 !important;
}

.MuiPaper-root-MuiAccordion-root:first-of-type {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.layout__main__section__component--data__element.MuiAccordion-root {
  box-shadow: none;
}
