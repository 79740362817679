@use "../utils/index" as u;

.btnMostrarResultado {
  padding: 16px;
  border-radius: 50px;
  color: white;
  background-color: u.$dodger-blue;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  &--mostrar {
    width: 50%;
    max-width: 200px;
  }

  &--vaciar {
    width: 105px;
  }
}
