@use "../utils" as u;

.initPreview {
  &__title {
    font-weight: 700;
    line-height: 32px;
  }

  &__text {
    font-weight: 400;
    line-height: 24px;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    &__description-container {
      display: flex;
      flex-direction: column;
      gap: 32px;

      &__description {
        width: fit-content;
        min-width: 239px;
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }

  &__btn-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35px;

    &__input-container {
      display: flex;
      gap: 16px;
      padding: 0px 16px;
      align-items: center;
      justify-content: center;
    }

    &__input {
      width: u.rem(16);
      max-width: 30px;
      height: u.rem(16);
      max-height: 30px;
    }

    &__btn {
      position: relative;
      width: 85%;
      height: 50px;
      overflow: hidden;
      font-size: 1.375em;
      font-weight: 700;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: medium;
      margin: 0px;
      padding: 0px;
      text-align: center;
      text-decoration: none;
      font-family: inherit;
      appearance: none;
      flex-shrink: 0;
      border-radius: 20px;
      color: u.$white;
    }

    &__disclaimer {
      text-align: center;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
