@use "../utils/index" as u;

.selectRows {
  width: fit-content;
  height: fit-content;
  background-color: white;
  border: 1px solid u.$catskill-white;
  border-radius: 16px;
  padding: 16px;
  position: absolute;
  z-index: 4;
  right: 0;
  top: 48px;
  color: u.$shark;
  font-family: u.$font_regular;
  letter-spacing: 0;
  font-size: u.rem(14);
  line-height: u.rem(18);

  &--inputs {
    width: 100%;
    height: u.rem(40);
    text-wrap: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--inputs .MuiTypography-body1 {
    letter-spacing: 0 !important;
    font-size: u.rem(14) !important;
    line-height: u.rem(18) !important;
  }

  &--btn {
    margin-top: 8px;
  }
}

/*
    Checkbox
*/

//tilde
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: u.$dodger-blue;
}

//recuadro
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: u.$dodger-blue !important;
  border-radius: 16px !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: u.$font_regular !important;
  letter-spacing: 0 !important;
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
}

/*
  SelectInputs
*/

.selectInputs {
  left: 0 !important;
}

.icon-arrastrar {
  cursor: pointer;
}
