@use "../utils/" as u;

.linkPreview {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    position: relative;
    padding-bottom: 24px;
  }

  &__separator {
    position: absolute;
    left: -24px;
    bottom: 0;
    width: calc(100% + 48px);
    border-bottom: 1px solid u.$catskill-white;
  }

  &__component-container {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    padding: 24px;
  }

  &__logo {
    width: 100%;
    max-width: 150px;
  }
}
