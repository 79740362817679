.formPreview {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    &__text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &__form {
    width: 100%;
    min-height: 35vh;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
