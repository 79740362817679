@use "../utils" as u;

.fraudScore {
  &__title {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__progressBar {
    height: u.rem(8);
    width: calc(100% - 32px);
    border-radius: 8px;
    background-color: u.$catskill-white;
    align-self: flex-end;
    position: relative;

    &__progress {
      position: absolute;
      height: 8px;
      left: 0;
      z-index: 1;
      border-radius: 8px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;

    &__item {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__icon,
    &__icon::before {
      display: inline-block;
      width: u.rem(20);
      height: u.rem(20);
      font-size: u.rem(20);
      margin: 0;
    }
  }
}
