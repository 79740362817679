@use "../utils/" as u;

.notifications-list {
  padding: 24px 16px;
  padding-right: 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 85vh;
  position: absolute;
  top: 36px;
  right: 0;
  z-index: 99999;
  box-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.14);
  background-color: u.$white;
  min-width: 420px;
  width: fit-content;
  border-radius: 10px;

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__title > div {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__title h4 {
    margin-left: 8px;
    width: 100%;
  }

  &__title a {
    color: u.$shark;
    text-decoration: none;
    display: block;
  }

  &__btn,
  &__btn > div {
    width: u.rem(24);
    height: u.rem(24);
    font-size: u.rem(24);
  }

  &__btn > div::before {
    display: inline-block;
    margin: 0;
    width: u.rem(24);
    height: u.rem(24);
    font-size: u.rem(24);
  }

  &__container {
    padding-right: 10px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
