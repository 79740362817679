@use "../utils/index" as u;

.autoCompleteSelect {
  width: 100%;
  max-width: 350px;
  background-color: u.$zircon;
  border-radius: 10px;
  border: 1px solid u.$catskill-white;
  color: u.$shark;
  font-family: u.$font_regular;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-top: 0;
    border-bottom: 1px solid u.$catskill-white;

    &--icon {
      width: u.rem(20);
      height: u.rem(20);
    }

    &--icon-check {
      width: u.rem(24);
      height: u.rem(24);
    }

    &--icon::before {
      font-size: u.rem(20);
      margin: 0;
    }

    &--icon-check::before {
      font-size: u.rem(24);
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;

    &--element {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      padding: 8px 16px;
    }

    &--flag {
      width: u.rem(24);
    }

    &--text {
      width: 60%;
      text-align: start;
    }
  }

  &__title .MuiSvgIcon-root {
    color: u.$dodger-blue;
  }
}
