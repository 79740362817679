@use "../utils" as u;

.networkReports {
  width: 100%;
  border: 1px solid u.$copperfield;
  background-color: u.$white;
  border-radius: 10px;
  padding: 20px 0px !important;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 0;

  &__title-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    &__flag {
      background-color: u.$peach-schnapps;
      padding: 2px 12px;
      border-radius: 8px;
    }

    &__flag > div::before {
      margin: 0 !important;
    }
  }

  &__title {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__reportsLists {
    height: fit-content;
    max-height: 50vh;
    overflow-y: scroll;
    scrollbar-width: auto;
  }

  &__report {
    &__separador {
      border-top: 1px solid u.$catskill-white;
    }

    &__description {
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.networkReports .MuiAccordionSummary-root {
  min-height: auto !important;
}

.networkReports.MuiAccordion-root {
  box-shadow: none;
}
