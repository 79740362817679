$space-1: 0.25rem;
$space-2: 0.5rem;
$space-3: 1rem;
$space-4: 1.5rem;
$space-5: 2rem;
$space-6: 2.5rem;
$space-7: 3rem;
$space-8: 3.5rem;
$space-9: 4rem;
$space-10: 4.5rem;
$space-11: 5rem;
$space-12: 6rem;
