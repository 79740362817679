@use "../utils/index" as u;

.lds-ring-small {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin: auto;
}

.lds-ring-small .ringFragment {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 8px;
  border: 8px solid u.$shark;
  border-radius: 10rem;
  animation: lds-ring-small 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: u.$shark transparent transparent transparent;
}

.lds-ring-small .ringFragment:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-small .ringFragment:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-small .ringFragment:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring-small {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
