.configLink {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__data-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__form {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__form .textInputComponent {
    padding-left: 28px;
  }

  &__inputRadio,
  &__input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__input {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__inputRadio > p,
  &__input-group > p {
    padding-left: 28px;
  }
}
