@use "../utils" as u;

.team {
  position: relative;

  &__form-search {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__form-search > form {
    width: 345px;
  }

  &__accounts-container {
    &--reference .team--dropdown-container {
      position: relative;
    }

    &--account-btn {
      width: 90%;
      max-width: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
    }

    &--account-btn > div::before {
      margin: 0 !important;
    }
  }

  &--btn-eliminar {
    border: 1px solid u.$shuttle-gray;
    border-radius: 50px;
    padding: 4px 12px;
  }

  &__table-container {
    min-height: 75vh;
    min-width: 75vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__table {
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &--loader .lds-ring {
    width: 7rem !important;
    height: 7rem !important;
  }

  &--loader .ringFragment {
    width: 6rem !important;
    height: 6rem !important;
  }

  &--dropdown-container {
    height: 0 !important;
    margin: 0 !important;
  }

  &--dropdown {
    height: 140px;
    min-width: 200px !important;
    padding: 16px;
    background-color: white;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.04),
      0px 10px 16px rgba(0, 0, 0, 0.0274815);

    &__content {
      background-color: u.$zircon;
      height: 100% !important;

      &--element {
        height: 28px;
        display: flex;
        align-items: center;
        margin-bottom: 2px;
      }
    }
  }

  &--invite {
    width: 155px;
    padding: 2px 12px;
    border-radius: 4px;
    background-color: u.$catskill-white;
    text-align: center;
  }
}
