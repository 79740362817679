@use "../utils/index" as u;

.rounded__pointer {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: u.$catskill-white;
  cursor: pointer;
  border: none;
}

.rounded__pointer--active {
  background-color: u.$danube;
}
