@use "../utils" as u;

.noCode {
  width: 75vw;
  max-width: 970px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  &__title {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__page {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__accordion {

    &__container {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    }

    &__title {
      font-weight: 700;
      font-size: 16px;
      padding: 1rem 1rem;
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-size: 14px;
  
      &__list {
        padding-left: 2rem;
      }
  
    }
  }


  &__table {
    border-spacing: 0;
    width: 100%;
    padding: 16px 8px;
    background-color: u.$white;
    border-radius: 10px;
    font-size: 14px;

    &--no-hire {
      @extend .noCode__table;
      padding: 24px 16px;
    }
  }

  &__table th {
    font-weight: 400;
    white-space: nowrap;
  }

  &__table-title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 8px;
  }

  &__row {
    padding: 16px 8px;
    text-align: left;

    &--separator {
      @extend .noCode__row;
      border-bottom: 1px solid u.$catskill-white;
      width: 79%;

      &--center {
        @extend .noCode__row--separator;
        text-align: center;
        width: 7%;
      }
    }

    &--center {
      @extend .noCode__row;
      text-align: center;
    }
  }
}
