@use "../utils/" as u;

.slider {
  &__container {
    min-height: 70vh;
    width: 90%;
    margin: auto;
    padding: 12px;
  }

  &__arrow {
    width: u.rem(40);
    height: u.rem(40);
    font-size: u.rem(40);

    &__prev {
      left: -40px;
    }

    &__next {
      right: -40px;
    }
  }

  &__arrow::before {
    font-size: u.rem(40);
    background-color: u.$white;
    color: u.$shark;
  }
}

.slick-current {
  padding: 8px;
}

.slick-current > div {
  width: 95%;
  min-height: 65vh;
  margin: auto;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
