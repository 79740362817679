@use "../utils/" as u;

.resumenConsultas {
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  justify-content: center;
  align-content: center;

  &__element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    &__title {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &--row {
        @extend .resumenConsultas__element__title;
        gap: 8px;
        flex-direction: row;
      }
    }

    &__icon {
      width: u.rem(18);
      height: u.rem(18);
    }

    &__icon::before {
      margin: 0;
      width: u.rem(18);
      height: u.rem(18);
      font-size: u.rem(18);
    }

    &--border {
      @extend .resumenConsultas__element;
      border-left: 1px solid u.$catskill-white;
      padding-left: 16px;
    }
  }
}
