@use "../utils" as u;

.page404 {
  display: flex;
  justify-content: center;
  padding-top: 48px;

  &__container {
    width: u.rem(385);
    height: u.rem(320);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__img {
    width: u.rem(150);
    max-width: 250px;
  }
}
