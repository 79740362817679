.signup {
  &__title {
    box-sizing: content-box;
    margin-bottom: 12px;
    padding-bottom: 8px;
  }

  &--h2-mb {
    margin-bottom: 16px;
  }

  &__form {
    height: 75%;
    min-height: 530px;

    &--btn,
    &--btn--disable {
      margin-bottom: 0;
      margin-top: 6px;
    }

    &--group {
      width: 100%;
      max-width: 500px;
      display: flex;
      justify-content: space-between;
    }

    &--group > .textInputComponent {
      width: 48% !important;
    }
  }

  &--loader {
    margin-bottom: 0 !important;
    margin-top: 20px;
    justify-content: center;
    width: 100%;
    max-width: 500px;
  }
}
