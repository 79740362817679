@use "../utils/index" as u;

.heading-1,
.MuiTypography-display1,
.heading-2,
.MuiTypography-display2,
.heading-3,
.MuiTypography-headline,
.heading-4,
.MuiTypography-title,
.text-4 {
  font-family: u.$font_bold !important;
}

.text-1,
.MuiTypography-body1,
.MuiTypography-body2,
.MuiButton-root,
.text-2,
.text-3,
.text-5 {
  font-family: u.$font_regular !important;
}

.heading-1,
.MuiTypography-display1,
.heading-2,
.MuiTypography-display2,
.heading-3,
.MuiTypography-headline,
.heading-4,
.MuiTypography-title,
.text-1,
.text-2,
.text-3,
.text-4,
.btn-text,
.MuiButton-root {
  letter-spacing: 0;
}

.heading-1,
.MuiTypography-display1 {
  font-size: u.rem(32);
  line-height: u.rem(42);
}

.heading-2,
.MuiTypography-display2 {
  font-size: u.rem(24);
  line-height: u.rem(32);
}

.heading-3,
.MuiTypography-headline {
  font-size: u.rem(20);
  line-height: u.rem(26);
}

.heading-4,
.MuiTypography-title {
  font-size: 1rem;
  line-height: u.rem(26);
}

.text-1 {
  font-size: u.rem(16);
  line-height: u.rem(22);

  &--bold {
    @extend .text-1;
    font-family: u.$font_bold !important;

    &--t2 {
      @extend .text-1--bold;
      line-height: u.rem(18);
    }
  }
}

.text-2 {
  font-size: u.rem(14) !important;
  line-height: u.rem(18) !important;

  &--bold {
    @extend .text-2;
    font-family: u.$font_bold !important;
  }
}

.text-3 {
  font-size: u.rem(12);
  line-height: u.rem(16);

  &--bold {
    @extend .text-3;
    font-family: u.$font_bold;
  }
}

.text-4 {
  font-size: u.rem(12);
  line-height: u.rem(16);
}

.text-5 {
  font-size: u.rem(10);
  line-height: u.rem(12);
}

.btn-text,
.MuiButton-root {
  font-family: u.$font_medium !important;
  font-size: u.rem(16);
  line-height: u.rem(18);
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}
