@use "../utils/" as u;

.shareLinkForm {
  min-width: 760px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 16px;

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;

    &__icon {
      width: u.rem(24);
      height: u.rem(24);
    }

    &__icon::before {
      margin: 0;
      font-size: u.rem(24);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__web {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__input-group {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &__input-group .MuiOutlinedInput-root fieldset,
      &__input-group .custom-input,
      &__input-group .custom-label,
      &__input-group .textInputComponent {
        max-width: none !important;
      }

      &__input-group .textInputComponent {
        width: 75% !important;
      }

      &__input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid u.$catskill-white;
        padding: 12px 8px;
        border-radius: 4px;
        max-width: 75%;
        position: relative;
      }
    }

    &__qr {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &__img-container {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border: 1px solid u.$shuttle-gray;
        border-radius: 4px;
      }

      &__btn-container {
        display: flex;
        gap: 16px;
      }
    }
  }
}
