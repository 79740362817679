@use "../utils/" as u;

.processingBatch {
  max-width: 30rem;
  min-height: 20rem;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 24px;

  &__container {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  &__icon {
    width: u.rem(64);
    height: u.rem(64);
  }

  &__icon::before {
    margin: 0;
    font-size: u.rem(64);
  }

  &__error-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  &__error-list {
    width: 80%;
    padding: 8px;
    padding-right: 16px;
    border: 1px solid u.$catskill-white;
    border-radius: 10px;
    height: 95px;
    overflow-y: scroll;
  }

  &__error-list > ul {
    list-style-position: inside;
  }

  /*
    Barra de Scroll firefox
    */
  &__error-list {
    scrollbar-color: u.$shark u.$shark;
    scrollbar-width: auto;
  }

  /*
    Barra de Scroll (chrome, opera, etc)
  */
  &__error-list::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &__error-list::-webkit-scrollbar:vertical {
    width: 8px;
  }

  &__error-list::-webkit-scrollbar:horizontal {
    height: 7px;
  }

  &__error-list::-webkit-scrollbar-button:increment,
  &__error-list::-webkit-scrollbar-button {
    display: none;
  }

  &__error-list::-webkit-scrollbar-thumb {
    background-color: u.$shark;
    border-radius: 20px;
    border: 8px solid u.$shark;
  }

  &__error-list::-webkit-scrollbar-thumb:hover {
    background-color: u.$shark;
  }

  &__error-list::-webkit-scrollbar-track {
    border-radius: 20px;
  }
}

.processingBatch p {
  width: 80%;
}
