@use "../utils" as u;

.inputUserID {
  width: 100%;
  margin-bottom: 32px;
  padding: 16px;
  border: 1px solid u.$catskill-white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  background-color: u.$white;

  &__switch {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.input-slide-enter {
  height: 0;
  opacity: 0;
}

.input-slide-enter-active {
  height: auto;
  opacity: 1;
  transition: height ease-in 1s, opacity ease-in 150ms;
}

.input-slide-enter-done {
  height: auto;
  opacity: 1;
}

.input-slide-exit {
  height: auto;
  opacity: 1;
}

.input-slide-exit-active {
  height: 0;
  opacity: 0;
  transition: height ease-in 1s, opacity ease-in 150ms;
}

.input-slide-exit-done {
  height: 0;
  opacity: 0;
}
